import PT from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { callApi } from 'app/actions/api';
import { selectAPIStatus } from 'app/selectors/api';
import { selectConsoleCategories } from 'app/selectors/categories';
import { getAllConsoleCategories } from 'app/epics/categories';
import * as types from 'app/types';

import Button from 'app/components/ui/Button';

import css from 'app/styles/editors/game-categories-editor.css';
import { updateGameCategories } from 'app/epics/games';

export class GameCategoriesEditor extends React.PureComponent {
	static propTypes = {
		dispatch: PT.func.isRequired,
		categories: PT.arrayOf(types.category),
		getAPIStatus: types.apiStatus.isRequired,
		updateAPIStatus: types.apiStatus.isRequired,
		game: types.game.isRequired,
	};

	static defaultProps = {
		categories: [],
	};

	// state
	constructor(props) {
		super(props);

		const { game } = props;

		this.state = {
			activeCategories: game.details?.categories || [],
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;
		dispatch(callApi(getAllConsoleCategories.id));
	}

	handleSubmitCategories = () => {
		const { dispatch, game } = this.props;

		const categories = this.state.activeCategories.map(category => category.id);

		dispatch(callApi(updateGameCategories.id, { id: game.details.id, data: { categories } }));
	};

	render() {
		const { categories, getAPIStatus, updateAPIStatus } = this.props;

		if (getAPIStatus.error) {
			return (<div>Error</div>);
		} if (categories.length === 0 && !getAPIStatus.pending) {
			return (<div>No data?! Hang on.</div>);
		} if (categories.length === 0 && getAPIStatus.pending) {
			return (<div>Loading...</div>);
		}

		const activeCategoryIDs = this.state.activeCategories.map(category => category.id);
		const inactiveCategories = categories.filter(category => !activeCategoryIDs.includes(category.id));

		const activeCategoriesSorted = this.state.activeCategories.sort((a, b) => a.id - b.id);

		return (
			<div>

				<Button
					primary
					disabled={updateAPIStatus.pending}
					onClick={this.handleSubmitCategories}
				>
					Save
				</Button>

				{updateAPIStatus.success
					&& (
						<div>
							Categories saved!
						</div>
					)}

				<h3>Active Categories</h3>
				<div className={css.categoryList}>
					{activeCategoriesSorted.map(category => (
						<Button
							primary
							size="small"
							key={category.id}
							onClick={() => {
								this.setState({
									activeCategories: this.state.activeCategories.filter(c => c.id !== category.id),
								});
							}}
						>
							{category.title}
						</Button>
					))}
				</div>

				<h3>Inactive Categories</h3>
				<div className={css.categoryList}>
					{inactiveCategories.map(category => (
						<Button
							primary={false}
							size="small"
							key={category.id}
							onClick={() => {
								this.setState({
									activeCategories: [...this.state.activeCategories, category],
								});
							}}
						>
							{category.title}
						</Button>
					))}
				</div>
			</div>
		);
	}
}

export default connect(state => ({
	categories: selectConsoleCategories(state),
	getAPIStatus: selectAPIStatus(state, getAllConsoleCategories.id),
	updateAPIStatus: selectAPIStatus(state, updateGameCategories.id),
}))(GameCategoriesEditor);
