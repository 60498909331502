import React from 'react';

export default class IconHouse extends React.PureComponent {
	render() {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="currentColor" fill="none">
				{' '}
				<path d="M0 0h24v24H0z" stroke="none" />
				{' '}
				<path d="M3 21V8l9-4 9 4v13" />
				{' '}
				<path d="M13 13h4v8H7v-6h6" />
				{' '}
				<path d="M13 21v-9a1 1 0 0 0-1-1h-2a1 1 0 0 0-1 1v3" />
				{' '}
			</svg>
		);
	}
}
