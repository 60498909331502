import React from 'react';

export default class IconLocalisation extends React.PureComponent {
	render() {
		return (
			<svg viewBox="0 0 736 815" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path fillRule="evenodd" clipRule="evenodd" d="M264.53 350.51C136.44 350.51 32.62 454.34 32.62 582.42C32.62 635.97 50.78 685.28 81.26 724.53L0 814.33H264.53C392.61 814.33 496.44 710.5 496.44 582.42C496.44 454.34 392.61 350.51 264.53 350.51V350.51ZM291.42 225.69H342.52V276.79H291.42V225.69V225.69ZM427.08 0C565.84 0 678.32 112.48 678.32 251.24C678.32 301.51 663.32 350.46 635.13 392.06L735.06 502.48H492.56C487.83 489 481.94 476.08 475.02 463.82H648.16L585.2 394.24C620.18 349.2 639.66 309.35 639.66 251.24C639.66 133.85 544.47 38.66 427.08 38.66C309.69 38.66 214.5 133.85 214.5 251.24C214.5 283.96 221.91 314.94 235.11 342.62C221.81 344.24 208.82 346.94 196.28 350.63C183.13 320.14 175.84 286.54 175.84 251.24C175.84 112.49 288.33 0 427.08 0V0ZM401.52 225.69H452.62V276.79H401.52V225.69V225.69ZM511.64 225.69H562.74V276.79H511.64V225.69V225.69ZM128.87 556.87H179.96V607.97H128.87V556.87ZM238.97 556.87H290.07V607.97H238.97V556.87ZM349.09 556.87H400.18V607.97H349.09V556.87Z" fill="black" />
			</svg>
		);
	}
}
