import React from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';

import { callApi } from 'app/actions/api';
import { deleteSeries } from 'app/epics/series';
import { selectAPIStatus } from 'app/selectors/api';

import Button from 'app/components/ui/Button';
import Title from 'app/components/ui/Title';

import * as types from 'app/types';

class SeriesDelete extends React.PureComponent {
	static propTypes = {
		dispatch: PT.func.isRequired,
		match: PT.shape({
			params: PT.shape({
				id: PT.string.isRequired,
				title: PT.string.isRequired,
			}).isRequired,
		}).isRequired,
		deleteAPIStatus: types.apiStatus.isRequired,
	};

	handleDeleteClick = () => {
		const { dispatch, match } = this.props;
		const data = { id: match.params.id };
		dispatch(callApi(deleteSeries.id, data));
	};

	render() {
		const { match, deleteAPIStatus } = this.props;
		const { params } = match;

		if (!params.id) return <p>Error! Series ID is not found.</p>;

		return (
			<>
				<Title>
					❗️Confirm deletion of &quot;
					{params.title}
					&quot;
				</Title>

				<p>
					You are about to delete &quot;
					{params.title}
					&quot; series. All related games will be deattached from it. Click DELETE if you want to proceed.
				</p>

				{deleteAPIStatus.success && (
					<Button disabled onClick={this.handleDeleteClick}>DELETED</Button>
				)}
				{deleteAPIStatus.error && (
					<p>
						<strong>Error</strong>
						:
						{' '}
						{deleteAPIStatus.error}
					</p>
				)}
				{!deleteAPIStatus.success && (
					<Button onClick={this.handleDeleteClick}>
						I want to DELETE &quot;
						{params.title}
						&quot; series
					</Button>
				)}
			</>
		);
	}
}

export default connect(state => ({
	deleteAPIStatus: selectAPIStatus(state, deleteSeries.id),
}))(SeriesDelete);
