/* eslint-disable no-alert */
import PT from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Fuse from 'fuse.js';

import { callApi } from 'app/actions/api';
import Title from 'app/components/ui/Title';
import Button from 'app/components/ui/Button';
import DataTable from 'app/components/ui/DataTable';
import { selectAPIStatus } from 'app/selectors/api';
import { getSeries, addSeries } from 'app/epics/series';
import { selectSeries } from 'app/selectors/series';
import * as types from 'app/types';

export class SeriesOverview extends React.PureComponent {
	static propTypes = {
		dispatch: PT.func.isRequired,
		series: PT.arrayOf(types.series).isRequired,
		seriesApiStatus: types.apiStatus.isRequired,
		addSeriesAPIStatus: types.apiStatus.isRequired,
	};

	static columns = [
		{
			selector: row => row.id,
			name: 'ID',
			width: '200px',
			sortable: true,
		},
		{
			name: 'Title',
			cell: row => (
				<Link to={`/series/${row.id}/view`}>{row.title}</Link>
			),
			sortable: true,
		},
		{
			name: 'Edit',
			cell: row => <Link to={`/series/delete/${row.id}/${row.title}`}>❌ Delete</Link>,
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		},
	];

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(callApi(getSeries.id));
	}

	UNSAFE_componentWillReceiveProps({ addSeriesAPIStatus }) {
		if (addSeriesAPIStatus.error !== this.props.addSeriesAPIStatus.error) {
			// Something went wrong. Show an error ;(
			alert(`Can not add a new series: ${addSeriesAPIStatus.error}`);
		}
		if (addSeriesAPIStatus.success !== this.props.addSeriesAPIStatus.success) {
			// All good!
			alert('New series has been added!');
		}
	}

	handleCreateNewSeries = () => {
		const { dispatch } = this.props;
		const dirtyTitle = prompt('How would you like to name a new series?');
		if (dirtyTitle) {
			const title = dirtyTitle.replace(/[^a-zA-Z ]/g, '');
			const data = { title };
			if (title) {
				dispatch(callApi(addSeries.id, data));
			}
		}
	};

	render() {
		const { seriesApiStatus, series } = this.props;

		const searchIndex = new Fuse(series, {
			caseSensitive: false,
			shouldSort: true,
			threshold: 0.2,
			distance: 100,
			findAllMatches: true,
			useExtendedSearch: true,
			keys: [
				'id',
				'title',
			],
		});

		return (
			<>
				<Title>Series</Title>
				<Button onClick={this.handleCreateNewSeries}>Add series</Button>
				<DataTable
					columns={SeriesOverview.columns}
					data={series}
					searchIndex={searchIndex}
					apiStatus={seriesApiStatus}
				/>
			</>
		);
	}
}

export default connect(state => ({
	series: selectSeries(state),
	seriesApiStatus: selectAPIStatus(state, getSeries.id),
	addSeriesAPIStatus: selectAPIStatus(state, addSeries.id),
}))(SeriesOverview);
