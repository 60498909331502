import debounce from 'just-debounce';
import React from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';

import { selectGamesList } from 'app/selectors/games';
import { selectCategoryList } from 'app/selectors/categories';
import { selectQuery } from 'app/selectors/search';
import { updateQuery } from 'app/actions/search';

import * as types from 'app/types';
import css from 'app/styles/ui/inline-search.css';

export class InlineSearch extends React.PureComponent {
	static propTypes = {
		dispatch: PT.func.isRequired,
		onSelect: PT.func.isRequired,
		games: PT.arrayOf(types.game).isRequired,
		categories: PT.arrayOf(types.category).isRequired,
		type: PT.oneOf(['game', 'category']),
		filterIDs: PT.arrayOf(PT.number),
		query: PT.string.isRequired,
	};

	static defaultProps = {
		type: 'game',
		filterIDs: [],
	};

	getResults = debounce(query => {
		const { dispatch } = this.props;

		dispatch(updateQuery({ query }));
	}, 200);

	handleSelect = item => {
		const { onSelect } = this.props;

		onSelect(item);
	};

	handleChangeQuery = ev => {
		const query = ev.target.value;

		this.getResults(query);
	};

	render() {
		const { games, categories, type, filterIDs, query } = this.props;

		let list = type === 'category' ? categories : games;

		if (list) {
			list = list.filter(o => !filterIDs.includes(o.id));
		} else {
			list = [];
		}

		return (
			<div className={css.queryWrapper}>
				<input
					className={css.query}
					onChange={this.handleChangeQuery}
					placeholder="Search to add items"
					type="search"
					defaultValue={query}
				/>
				{list.length > 0 && query !== '' && (
					<div className={css.results}>
						{list.map(item => (
							<button
								key={item.id}
								onClick={() => this.handleSelect(item)}
								type="button"
							>
								{item.title}
								{' '}
								(
								{item.id}
								)
							</button>
						))}
					</div>
				)}
			</div>
		);
	}
}

export default connect(state => ({
	games: selectGamesList(state),
	categories: selectCategoryList(state),
	query: selectQuery(state),
}))(InlineSearch);
