import React from 'react';

import Title from 'app/components/ui/Title';
import CreativesEditor from 'app/components/editors/CreativesEditor';

export default class CreativeAdd extends React.PureComponent {
	render() {
		return (
			<>
				<Title>Add Creative</Title>
				<CreativesEditor creative={
					{
						start_date: '2022-01-01',
						end_date: '2038-01-01',
					}
				}
				/>
			</>
		);
	}
}
