import React from 'react';

import Title from 'app/components/ui/Title';
import GameDataTable from 'app/components/GameDataTable';

export default class GameOverview extends React.PureComponent {
	render() {
		return (
			<>
				<Title>Games</Title>
				<GameDataTable />
			</>
		);
	}
}
