import React from 'react';

export default class IconAds extends React.PureComponent {
	render() {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 325.6 325.6">
				<path d="M281.5 247.4l-12.2-12.6c4.2.5 8.2-2.2 9.3-6.4 1.1-4.6-1.6-9.2-6.2-10.3l-28.8-7.3c-2.9-.7-6 .1-8.1 2.2-2.1 2.1-3 5.2-2.2 8.1l7.3 28.8c1 3.9 4.4 6.4 8.2 6.4.7 0 1.4-.1 2.1-.3 4.2-1.1 6.9-5.2 6.3-9.4l12.1 12.5c1.7 1.7 3.9 2.6 6.1 2.6 2.1 0 4.3-.8 5.9-2.4 3.4-3.2 3.5-8.6.2-11.9zM140.3 153.6c-.5-1.4-1.9-2.4-3.4-2.4h-6.1c-1.5 0-2.9.9-3.4 2.4L106 210.9c-.4 1.1-.3 2.4.4 3.4s1.8 1.6 3 1.6h5.6c1.5 0 2.9-.9 3.4-2.4l6.3-17h18.4l6.4 17c.5 1.4 1.9 2.4 3.4 2.4h5.6c2 0 3.6-1.6 3.6-3.6 0-.6-.1-1.1-.4-1.6l-21.4-57.1zM129.4 184l4.5-12.1 4.6 12.1h-9.1zM186.9 151h-15.8c-2 0-3.6 1.6-3.6 3.6v57.2c0 2 1.6 3.6 3.6 3.6h15.8c18.1 0 32.9-14.5 32.9-32.2 0-17.7-14.8-32.2-32.9-32.2zm0 52H180v-39.5h6.9c11.3 0 20.4 8.9 20.4 19.8s-9.1 19.7-20.4 19.7z" />
				<path d="M308.1 8.5H17.5C7.9 8.5 0 16.3 0 26v273.7c0 9.6 7.9 17.5 17.5 17.5h290.6c9.6 0 17.5-7.9 17.5-17.5V26c0-9.7-7.9-17.5-17.5-17.5zm-34.6 26.8c8 0 14.5 6.5 14.5 14.5s-6.5 14.5-14.5 14.5S259 57.8 259 49.8s6.5-14.5 14.5-14.5zm-49.2 0c8 0 14.5 6.5 14.5 14.5s-6.5 14.5-14.5 14.5-14.5-6.5-14.5-14.5 6.5-14.5 14.5-14.5zm-179.9 7h58.5c4.1 0 7.5 3.4 7.5 7.5s-3.4 7.5-7.5 7.5H44.4c-4.1 0-7.5-3.4-7.5-7.5s3.4-7.5 7.5-7.5zm253.4 244.5c0 1.3-1 2.3-2.3 2.3H30.1c-1.3 0-2.3-1-2.3-2.3V98.3h270v188.5z" />
			</svg>
		);
	}
}
