export function metadataSchema({ sites }) {
	const domains = Object.keys(sites).map(id => ({ id: parseInt(id, 10), domain: sites[id].domain }));

	return {
		type: 'object',
		required: [],
		properties: {
			metadata: {
				type: 'array',
				title: 'Metadata',
				items: {
					type: 'object',
					properties: {
						site_id: {
							type: 'number',
							enum: domains.map(d => d.id),
							enumNames: domains.map(d => d.domain),
						},
						title: {
							type: 'string',
						},
						description: {
							type: 'string',
						},
					},
				},
			},
		},
	};
}

export const uiMetadataSchema = {
	metadata: {
		items: {
			description: {
				'ui:widget': 'textarea',
			},
		},
		'ui:options': {
			addable: true,
			orderable: false,
			removable: true,
		},
	},
};
