import { apiSuccess } from 'app/actions/api';
import { getAllCreatives, getCreative } from 'app/epics/creatives';
import { displayFormats, videoFormats, customFormats } from 'app/forms/creative';
import { SERVICE_ENV } from 'app/utils/api';

export const initialState = {
	creatives: [],
	creative: {},
};

export default function creativesReducer(state = initialState, action) {
	if (action.type === apiSuccess.type && action.id === getAllCreatives.id) {
		return {
			...state,
			creatives: action.response,
		};
	}

	if (action.type === apiSuccess.type && action.id === getCreative.id) {
		const type = action.response?.type;
		const asset = action.response?.asset;

		const CDNLocation = `https://v${['acceptance', 'local'].includes(SERVICE_ENV) ? '-acceptance' : ''}.poki-cdn.com`;
		// eslint-disable-next-line camelcase
		const videoAsset = `${CDNLocation}/${action.response?.video_asset_high}`;

		const creative = {
			[action.response.id]: {
				...action.response,
				...(Object.keys(displayFormats).includes(type) ? { asset_display: asset } : {}),
				...(Object.keys(videoFormats).includes(type) ? { asset_video: videoAsset } : {}),
				...(Object.keys(customFormats).includes(type) ? { asset_imvita: asset } : {}),
			},
		};

		return {
			...state,
			creative: {
				...state.creative,
				...creative,
			},
		};
	}

	return state;
}
