import { ajax } from 'rxjs/ajax';
import { switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { push } from 'react-router-redux';

import { setUser, handleAuthCallback } from 'app/actions/session';
import { SERVICE_ENV } from '../utils/api';

export function handleAuthCallbackEpic(action$) {
	let authURL = 'https://auth-acceptance.poki.io/auth/exchange';
	if (SERVICE_ENV === 'production') {
		authURL = 'https://auth.poki.io/auth/exchange';
	}

	return action$.pipe(
		ofType(handleAuthCallback.type),
		switchMap(({ exchangeToken }) => (
			ajax({
				url: authURL,
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ exchange_token: exchangeToken }),
			}).pipe(
				tap(({ response }) => {
					window.localStorage.setItem('refresh_token', response.refresh_token);
					window.localStorage.setItem('access_token', response.access_token);
					window.localStorage.setItem('expires', Date.now() + (response.ttl * 1000));
				}),
				switchMap(({ response }) => {
					const claims = JSON.parse(atob(response.refresh_token.split('.', 3)[1]));
					return of(
						setUser({ user: claims }),
						push('/'),
					);
				}),
			)
		)),
	);
}
