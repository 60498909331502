/* eslint-disable no-alert */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Fuse from 'fuse.js';

import Title from 'app/components/ui/Title';
import DataTable from 'app/components/ui/DataTable';
import Picture from 'app/components/ui/Picture';
import api from 'app/utils/api';

import css from 'app/styles/game-data-table.css'; // reuse

const names = ['charlatan', 'daredevil', 'entrepreneur', 'explorer', 'fortune-hunter', 'gambler', 'globetrotter', 'hero', 'madcap', 'mercenary', 'opportunist', 'pioneer', 'pirate', 'speculator', 'stunt-person', 'swashbuckler', 'traveler', 'voyager', 'wanderer', 'VIP', 'bigwig', 'celeb', 'figure', 'hotshot', 'major-leaguer', 'star', 'superstar ', 'backer', 'challenger', 'champ', 'conqueror', 'defender', 'endorser', 'exponent', 'expounder', 'guardian', 'paladin', 'partisan', 'patron', 'protector', 'supporter', 'top-dog', 'vindicator', 'warrior', 'winner'];
const jokeName = names[Math.floor(Math.random() * names.length)];
const nrFormat = new Intl.NumberFormat('nl');

export default function Dashboard() {
	const [realtimeData, setRealTimeData] = useState([{
		image: {},
		title: 'Loading',
		count: 0,
	}]);

	const [totalUsers, setTotal] = useState(0);

	const columns = [
		{
			name: 'Image',
			selector: row => row.image,
			cell: row => (
				<Picture
					alt={row.slug}
					className={css.image}
					image={row.image}
					size={[30, 30]}
				/>
			),
			sortable: true,
			width: '90px',
		},
		{
			name: 'Title',
			cell: row => (
				<Link to={`/games/${row.id}/edit`}>{row.title}</Link>
			),
			sortable: true,
		},
		{
			id: 'count',
			name: 'Live players',
			selector: row => row.count,
			cell: row => (
				<span>{nrFormat.format(row.count)}</span>
			),
			sortable: true,
		}];

	const searchIndex = new Fuse(realtimeData, {
		caseSensitive: false,
		shouldSort: true,
		threshold: 0.3,
		distance: 100,
		findAllMatches: true,
		useExtendedSearch: true,
		keys: [
			'title',
		],
	});

	useEffect(() => {
		// Will be updated once we've a seperate call for the playground-homepage
		api('https://api.poki.io/list/game/HOME?site=3&limit=100')
			.toPromise()
			.then(({ response }) => {
				const games = response?.content?.map(game => ({
					id: game.id,
					title: game.title,
					image: game.image,
					p4dID: game.pokifordevs_game_id,
				}));

				const keyedGames = [];
				games.forEach(game => {
					keyedGames[game.p4dID] = game;
				});

				const allIds = games.map(game => game.p4dID).sort().join(',');

				let total = 0;

				api(`https://realtime.poki.com/metrics/playground/${allIds}`)
					.toPromise()
					.then(({ response: data }) => {
						setRealTimeData((Object.keys(data?.metrics) || []).map(gameID => {
							const g = keyedGames[gameID];
							const count = data?.metrics?.[gameID];

							total += count;

							return {
								id: g.id,
								image: g.image,
								title: g.title,
								count,
							};
						}));

						setTotal(nrFormat.format(total));
					}).catch(() => { });
			}).catch(() => { });
	}, []);

	return (
		<>
			<Title>
				Hi content
				{' '}
				{jokeName}
				{' '}
				👋
			</Title>
			<Title>
				{`Live players on the top-100 games: ${totalUsers} 🚀`}
			</Title>
			<DataTable
				columns={columns}
				data={realtimeData}
				searchIndex={searchIndex}
				apiStatus={{ pending: false, success: true }}
				paginationPerPage={100}
				defaultSortFieldId="count"
				defaultSortAsc={false}
			/>
		</>
	);
}
