export function gameDetailsSchema({ categories, series }) {
	return {
		definitions: {
			version: {
				type: 'object',
				required: [
					'content',
				],
				properties: {
					orientation: {
						title: 'Orientation',
						description: '',
						type: 'number',
						enum: [
							0,
							1,
							2,
						],
						enumNames: [
							'Both (works in any orientation)',
							'Landscape only',
							'Portrait only',
						],
					},
					content: {
						type: 'string',
						title: 'Content URL',
						description: 'Managed through P4D, if a change is needed please ask a developer',
					},
				},
			},
			desktopVersion: {
				type: 'object',
				required: [
					'content',
				],
				properties: {
					orientation: {
						title: 'Orientation',
						description: 'This field is deprecated and will be set to "Both" automatically',
						type: 'number',
						enum: [
							0,
							1,
							2,
						],
						enumNames: [
							'Both (works in any orientation)',
							'❌ Landscape only - deprecated',
							'❌ Portrait only - deprecated',
						],
						default: 0,
					},
					content: {
						type: 'string',
						title: 'Content URL',
						description: 'Managed through P4D, if a change is needed please ask a developer',
					},
				},
			},
		},

		dependencies: {
			platform: {
				oneOf: [{
					properties: {
						platform: {
							type: 'string',
							enum: [
								'Desktop Only',
							],
						},
						desktop: {
							title: 'Desktop settings',
							$ref: '#/definitions/desktopVersion',
						},
					},
				},
				{
					properties: {
						platform: {
							type: 'string',
							enum: [
								'Both',
							],
						},
						desktop: {
							title: 'Desktop settings',
							$ref: '#/definitions/desktopVersion',
						},
						mobile: {
							title: 'Mobile settings',
							$ref: '#/definitions/version',
						},
					},
				}],
			},
		},

		// Our form
		type: 'object',
		title: 'Game Editor',
		required: ['platform', 'category'],
		properties: {
			developer: {
				type: 'string',
				title: 'Developer',
			},
			category: {
				type: 'number',
				title: 'Belongs to category:',
				enum: categories.map(c => c.id),
				enumNames: categories.map(c => c.title),
			},
			series: {
				type: ['number', 'null'],
				title: 'Series',
				enum: series.map(s => s.id),
				enumNames: series.map(s => s.title),
			},
			keywords: {
				type: 'string',
				title: 'Advertising keywords',
			},
			play_store_url: {
				type: 'string',
				title: 'Google Play Store URL',
			},
			apple_store_url: {
				type: 'string',
				title: 'Apple Store URL',
			},
			steam_url: {
				type: 'string',
				title: 'Steam URL',
			},
			amazon_url: {
				type: 'string',
				title: 'Amazon URL',
			},
			microsoft_url: {
				type: 'string',
				title: 'Microsoft Store URL',
			},
			platform: {
				type: 'string',
				enum: [
					'Desktop Only',
					'Both',
				],
			},
			content_updated_at: {
				type: [
					'string',
					'null',
				],
				format: 'date',
				title: 'Updated at',
			},
			alternative_game: {
				type: 'number',
				title: 'Alternative game ID',
			},
			config: {
				type: 'string',
				title: 'Config',
			},
			alternative_game_enabled: {
				type: 'boolean',
				title: 'Show alternative game',
			},
			fullscreen: {
				type: 'boolean',
				title: 'Fullscreen',
			},
			explicit_orientation_message: {
				type: 'boolean',
				title: 'Explicit Orientation Message',
			},
			allow_smart_refresh: {
				type: 'boolean',
				title: 'Allow Smart Refresh',
			},
			family_blocked: {
				type: 'boolean',
				title: 'Blocked in Family Mode',
			},
		},
	};
}

export const uiGameDetailsSchema = {
	config: {
		'ui:widget': 'textarea',
	},
	desktop: {
		content: {
			'ui:disabled': true,
		},
		orientation: {
			'ui:enumDisabled': [
				1, 2,
			],
		},
	},
	mobile: {
		content: {
			'ui:disabled': true,
		},
	},
};
