import React from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';

import IconHeart from 'app/components/icons/IconHeart';
import { selectAPIStatus } from 'app/selectors/api';
import { combinedApiStatus } from 'app/utils/api';
import { callApi } from 'app/actions/api';
import { getAllGames } from 'app/epics/games';
import { getAllCategories, getAllConsoleCategories } from 'app/epics/categories';
import { getSites } from 'app/epics/sites';
import css from 'app/styles/blocking-init.css';

import * as types from 'app/types';

class BlockingInit extends React.PureComponent {
	static propTypes = {
		dispatch: PT.func.isRequired,
		getAllCategoriesAPIStatus: types.apiStatus.isRequired,
		getAllConsoleCategoriesAPIStatus: types.apiStatus.isRequired,
		getAllGamesAPIStatus: types.apiStatus.isRequired,
		getSitesAPIStatus: types.apiStatus.isRequired,
	};

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(callApi(getAllGames.id));
		dispatch(callApi(getAllCategories.id));
		dispatch(callApi(getAllConsoleCategories.id));
		dispatch(callApi(getSites.id));
	}

	render() {
		const { getAllCategoriesAPIStatus, getAllConsoleCategoriesAPIStatus, getAllGamesAPIStatus, getSitesAPIStatus } = this.props;

		const apiStatus = combinedApiStatus(getAllCategoriesAPIStatus, getAllConsoleCategoriesAPIStatus, getAllGamesAPIStatus, getSitesAPIStatus);

		if (apiStatus.pending) {
			return (
				<div className={css.overlay}>
					<div className={css.loading}>
						<IconHeart />
						Loading...
					</div>
				</div>
			);
		}

		if (apiStatus.error) {
			return (
				<div className={css.overlay}>
					❌ Woah this is bad, please reload 🔥
				</div>
			);
		}

		return null;
	}
}

export default connect(state => ({
	getAllCategoriesAPIStatus: selectAPIStatus(state, getAllCategories.id),
	getAllConsoleCategoriesAPIStatus: selectAPIStatus(state, getAllConsoleCategories.id),
	getAllGamesAPIStatus: selectAPIStatus(state, getAllGames.id),
	getSitesAPIStatus: selectAPIStatus(state, getSites.id),
}))(BlockingInit);
