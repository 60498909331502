import { apiSuccess } from 'app/actions/api';

import { getGame, getAllGames } from 'app/epics/games';
import { getCategoryGames } from 'app/epics/categories';

export const initialState = {
	games: {},
	data: {},
	success: false,
	error: undefined,
	pending: true,
};

export default function gamesReducer(state = initialState, action) {
	if (action.type === apiSuccess.type) {
		if (action.id === getAllGames.id) {
			const games = action.response.reduce((acc, item) => {
				acc[item.id] = item;
				return acc;
			}, {});

			return {
				...state,
				games,
			};
		}

		if (action.id === getCategoryGames.id) {
			return {
				...state,
				data: {
					...state.data,
					...action.response.games.reduce((acc, game) => {
						acc[game.id] = {
							...state.data[game.id],
							...game,
						};
						return acc;
					}, {}),
				},
			};
		}

		if (action.id === getGame.id) {
			return {
				...state,
				data: {
					...state.data,
					[action.options.id]: {
						...state.data[action.options.id],
						details: action.response,
					},
				},
			};
		}
	}

	return state;
}
