import PT from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import css from 'app/styles/editors/media-editor.css';
import * as types from 'app/types';
import { MediaImageThumbnail, MediaVideoThumbnail, MediaAlternativeThumbnail, MediaLandscapeScreenshot, MediaPortraitScreenshot, MediaMisc } from 'app/components/editors/util/mediaTypes';
import { selectMediasByID } from 'app/selectors/medias';
import MediaDragDropList from 'app/components/editors/MediaDragDropList';

class MediaEditor extends React.PureComponent {
	static propTypes = {
		mediaCollectionID: PT.string.isRequired,
		disableThumbnailEdit: PT.bool,
		medias: PT.arrayOf(types.media),
	};

	static defaultProps = {
		medias: [],
		disableThumbnailEdit: false,
	};

	render() {
		const { mediaCollectionID, medias, disableThumbnailEdit } = this.props;

		return (
			<div className={css.editor}>
				{!disableThumbnailEdit && <div className={css.column}><MediaDragDropList mediaCollectionID={mediaCollectionID} items={medias.filter(m => m.type === MediaImageThumbnail)} type={MediaImageThumbnail} /></div>}
				<div className={css.column}><MediaDragDropList mediaCollectionID={mediaCollectionID} items={medias.filter(m => m.type === MediaVideoThumbnail)} type={MediaVideoThumbnail} /></div>
				<div className={css.column}><MediaDragDropList mediaCollectionID={mediaCollectionID} items={medias.filter(m => m.type === MediaAlternativeThumbnail)} type={MediaAlternativeThumbnail} /></div>
				<div className={css.column}><MediaDragDropList mediaCollectionID={mediaCollectionID} items={medias.filter(m => m.type === MediaLandscapeScreenshot)} type={MediaLandscapeScreenshot} /></div>
				<div className={css.column}><MediaDragDropList mediaCollectionID={mediaCollectionID} items={medias.filter(m => m.type === MediaPortraitScreenshot)} type={MediaPortraitScreenshot} /></div>
				<div className={css.column}><MediaDragDropList mediaCollectionID={mediaCollectionID} items={medias.filter(m => m.type === MediaMisc)} type={MediaMisc} /></div>
			</div>
		);
	}
}

export default connect((state, { mediaCollectionID }) => ({
	mediaCollectionID,
	medias: selectMediasByID(state, mediaCollectionID),
}))(MediaEditor);
