import { ajax } from 'rxjs/ajax';
import { fromEvent, forkJoin, empty } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { apiEpic, SERVICE_ENV } from 'app/utils/api';
import { ofType } from 'redux-observable';
import { apiSuccess, callApi } from 'app/actions/api';
import { setUploadedMedia, startPollingStatus } from 'app/actions/medias';
import { getGame } from 'app/epics/games';
import { getCategory } from 'app/epics/categories';
import { MediaVideoThumbnail } from 'app/components/editors/util/mediaTypes';

export const getMedias = apiEpic(
	'medias/get',
	(api, { id }) => api(`/secure/media/${id}`),
);

export const uploadMedia = apiEpic(
	'medias/upload_media',
	(api, data) => {
		const observables$ = data?.files?.map(file => {
			const reader = new FileReader();
			const observable$ = fromEvent(reader, 'loadend').pipe(
				take(1),
				switchMap(() => {
					const apiUrls = {
						acceptance: 'https://video-compressor-acceptance.poki.io',
						production: 'https://video-compressor.poki.io',
						local: 'https://video-compressor-acceptance.poki.io',
					};
					const apiUrl = apiUrls[SERVICE_ENV] || apiUrls.acceptance;

					if (data.type === MediaVideoThumbnail) {
						return api(`${apiUrl}/v1/upload`, {
							body: {
								uploader_id: data.email,
								source_filename: file.path,
								source_filesize: reader.result.length,
								type: 'thumbnail',
							},
							method: 'POST',
							headers: {
								'Content-Type': 'application/json',
							},
						}).pipe(
							take(1),
							switchMap(({ status, response }) => {
								if (status === 200) {
									// Now we upload the actual file
									return ajax({
										url: response.upload_url,
										method: 'PUT',
										body: reader.result,
										headers: {
											'Content-Type': 'video/mp4',
										},
									}).pipe(
										tap(() => {
											window.store.dispatch(startPollingStatus({
												mediaCollectionID: data.id,
												compressorID: response.id,
												mediaType: data.type,
												path: file.path,
											}));
										}),
									);
								}

								return empty();
							}),
						);
					}

					return api(`/secure/media/${data.id}`, {
						body: {
							data: reader.result,
							type: data.type,
						},
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
					}).pipe(
						tap(response => {
							// No clue why this doesn't work with a map
							if (response.status === 200) {
								window.store.dispatch(setUploadedMedia({
									id: data.id,
									path: file.path,
								}));
							}
						}),
					);
				}),
			);

			if (data.type === MediaVideoThumbnail) {
				// Binary
				reader.readAsArrayBuffer(file);
			} else {
				// base64
				reader.readAsDataURL(file);
			}
			return observable$;
		});
		return forkJoin(...observables$).pipe(
			tap(() => {
				window.store.dispatch(
					callApi(getMedias.id, { id: data.id }),
				);
			}),
		);
	},
);

export const updateMediaOrder = apiEpic(
	'medias/update_media',
	(api, data) => api(`/secure/media/${data.id}`, {
		body: {
			type: data.type,
			order: data.order,
		},
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
		},
	}),
);

export const startPollingStatusOnServer = apiEpic(
	'medias/store_transcoded_files',
	(api, { path, mediaType, mediaCollectionID, compressorID }) => api(`/secure/media/${mediaCollectionID}/poll`, {
		body: {
			type: mediaType,
			compressor_id: compressorID,
		},
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
	}).pipe(
		tap(response => {
			if (response.status === 200) {
				window.store.dispatch(setUploadedMedia({
					id: mediaCollectionID,
					path,
				}));
			}
		}),
	),
);

export function startPollingStatusEpic(action$) {
	return action$.pipe(
		ofType(startPollingStatus.type),
		map(data => callApi(startPollingStatusOnServer.id, data)),
	);
}

export const deleteMedia = apiEpic(
	'medias/delete_media',
	(api, data) => api(`/secure/media/${data.id}`, {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
		},
	}).pipe(
		tap(() => {
			window.store.dispatch(
				callApi(getMedias.id, { id: data.media_collection_id }),
			);
		}),
	),
);

export function reloadMediaEpic(action$) {
	return action$.pipe(
		ofType(apiSuccess.type),
		filter(({ id }) => [getGame.id, getCategory.id].includes(id)),
		map(({ response: { media_collection_id: id } }) => callApi(getMedias.id, { id })),
	);
}
