import { apiEpic } from 'app/utils/api';

export const getLocalisations = apiEpic(
	'localisations/all',
	api => api('/secure/localisations?site=3'),
);

export const getLocalisation = apiEpic(
	'localisations/get',
	(api, { id }) => api(`/secure/localisations/name/${id}`),
);

export const createLocalisation = apiEpic(
	'localisations/create',
	(api, data) => api(`/secure/localisations/create/${data.id}`, {
		body: data.formData,
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
	}),
);

export const updateLocalisation = apiEpic(
	'localisations/update',
	(api, data) => api(`/secure/localisations/update/${data.id}`, {
		body: data.formData,
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
		},
	}),
);
