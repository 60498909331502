import { apiSuccess } from 'app/actions/api';
import { getAdSettings } from 'app/epics/adsettings';

export const initialState = {
	data: 'Loading...',
};

export default function adSettingsReducer(state = initialState, action) {
	if (action.type === apiSuccess.type) {
		if (action.id === getAdSettings.id) {
			return {
				...state,
				data: action.response,
			};
		}
	}
	return state;
}
