import { apiSuccess } from 'app/actions/api';
import { getAdsTxt } from 'app/epics/adstxt';

export const initialState = {
	data: 'Loading...',
};

export default function adsTxtReducer(state = initialState, action) {
	if (action.type === apiSuccess.type) {
		if (action.id === getAdsTxt.id) {
			return {
				...state,
				data: action.response,
			};
		}
	}
	return state;
}
