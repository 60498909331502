import PT from 'prop-types';
import React from 'react';

import IconCross from 'app/components/icons/IconCross';
import Picture from 'app/components/ui/Picture';
import css from 'app/styles/ui/list-tile.css';
import * as types from 'app/types';

export default class ListTile extends React.PureComponent {
	static propTypes = {
		data: PT.oneOfType([types.game, types.category]).isRequired,
		onRemove: PT.func,
	};

	static defaultProps = {
		onRemove: () => {},
	};

	render() {
		const { data, onRemove } = this.props;

		return (
			<div className={css.tile}>
				<Picture
					alt={data.title}
					className={css.picture}
					image={data.image}
					size={[132, 132]}
				/>
				<div className={css.title}>
					{data.title}
				</div>
				<button type="button" className={css.deleteButton} onClick={onRemove}>
					<IconCross />
				</button>
			</div>
		);
	}
}
