import { ofType } from 'redux-observable';
import { mapTo, ignoreElements, tap } from 'rxjs/operators';
import { LOCATION_CHANGE } from 'react-router-redux';

import { apiError, clearAllStatuses } from 'app/actions/api';

export function logApiErrorsEpic(action$) {
	return action$.pipe(
		ofType(apiError.type),
		tap(({ error }) => console.error('API Error Encountered ', error)),
		ignoreElements(),
	);
}

export function clearAllStatusesEpic(action$) {
	return action$.pipe(
		ofType(LOCATION_CHANGE),
		mapTo(clearAllStatuses()),
	);
}
