export function releaseStatusSchema({ sites }) {
	const domains = Object.keys(sites).map(id => ({ id: parseInt(id, 10), domain: sites[id].domain }));

	return {
		type: 'object',
		required: [],
		properties: {
			release_status: {
				type: 'string',
				title: 'Release Status',
				enum: [
					'not-released',
					'technical-test',
					'technical-test-with-content-restrictions',
					'soft-release',
					'soft-release-with-content-restrictions',
					'limited-release',
					'limited-release-with-content-restrictions',
					'full-release',
					'full-release-with-content-restrictions',
					'10k-test',
					'10k-test-with-content-restrictions',
					'no-link-release',
				],
				enumNames: [
					'Not Released',
					'Technical Test',
					'Technical Test with content restrictions',
					'Soft Release',
					'Soft Release with content restrictions',
					'Limited Release',
					'Limited Release with content restrictions',
					'Full Release',
					'Full Release with content restrictions',
					'10k Test',
					'10k Test with content restrictions',
					'No Link Release',
				],
				default: 'not-released',
			},
			release_status_overrides: {
				type: 'array',
				title: 'Release Status Overrides',
				items: {
					type: 'object',
					properties: {
						site_id: {
							type: 'number',
							title: 'Site',
							enum: domains.map(d => d.id),
							enumNames: domains.map(d => d.domain),
							default: domains[0].id,
						},
						action: {
							type: 'string',
							title: 'Action',
							enum: [
								'add',
								'remove',
							],
							enumNames: [
								'Add game to site',
								'Remove game from site',
							],
							default: 'add',
						},
					},
				},
			},
			release_status_history: {
				type: 'array',
				title: 'Release Status History',
				items: {
					type: 'object',
					properties: {
						release_status: {
							type: 'string',
							title: 'Release Status',
						},
						updated_by: {
							type: 'string',
							title: 'Updated By',
						},
						created_at: {
							type: 'string',
							title: 'Created At',
							format: 'date-time',
						},
					},
				},
			},
		},
	};
}

export const uiReleaseStatusSchema = {
	release_status_overrides: {
		'ui:options': {
			addable: true,
			orderable: false,
			removable: true,
		},
	},
	release_status_history: {
		'ui:options': {
			addable: false,
			orderable: false,
			removable: false,
		},
		items: {
			release_status: {
				'ui:disabled': true,
			},
			updated_by: {
				'ui:disabled': true,
			},
			created_at: {
				'ui:disabled': true,
			},
		},
	},
};
