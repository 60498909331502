import {
	apiFetch,
	apiError,
	apiSuccess,
	clearAllStatuses,
} from 'app/actions/api';

export const defaultState = {
	success: false,
	error: null,
	pending: false,
};

export default function apiReducer(state = {}, action) {
	if (action.type === apiFetch.type) {
		return {
			...state,
			[action.id]: {
				success: false,
				error: null,
				pending: true,
			},
		};
	}

	if (action.type === apiError.type) {
		return {
			...state,
			[action.id]: {
				...state[action.id],
				success: false,
				error: action.error,
				pending: false,
			},
		};
	}

	if (action.type === apiSuccess.type) {
		return {
			...state,
			[action.id]: {
				success: true,
				error: null,
				pending: false,
				// NOTE: Do not save data here, this is the responsibility of specific reducers
			},
		};
	}

	if (action.type === clearAllStatuses.type) {
		return {};
	}

	return state;
}
