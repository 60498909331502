import React from 'react';
import PT from 'prop-types';
import 'url-search-params-polyfill';
import { connect } from 'react-redux';

import { handleAuthCallback } from 'app/actions/session';
import css from 'app/styles/pages/login.css';

class AuthCallback extends React.PureComponent {
	static propTypes = {
		dispatch: PT.func.isRequired,
	};

	UNSAFE_componentWillMount() {
		const { dispatch } = this.props;
		const params = new URLSearchParams(window.location.search.slice(1));
		const exchangeToken = params.get('exchange_token');

		dispatch(handleAuthCallback({ exchangeToken }));
	}

	render() {
		return <div className={css.wrapper}>Please wait...</div>;
	}
}

export default connect()(AuthCallback);
