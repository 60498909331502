import React from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';

import { callApi } from 'app/actions/api';
import { getCreative } from 'app/epics/creatives';
import { getAllGames } from 'app/epics/games';

import { selectCreative } from 'app/selectors/creatives';
import Title from 'app/components/ui/Title';
import CreativesEditor from 'app/components/editors/CreativesEditor';
import { selectAPIStatus } from 'app/selectors/api';
import { combinedApiStatus } from 'app/utils/api';

import * as types from 'app/types';

class CreativesEdit extends React.PureComponent {
	static propTypes = {
		dispatch: PT.func.isRequired,
		creative: types.creative,
		match: PT.shape({
			params: PT.shape({
				id: PT.string.isRequired,
			}).isRequired,
		}).isRequired,
		getCreativeAPIStatus: types.apiStatus.isRequired,
		getAllGamesAPIStatus: types.apiStatus.isRequired,
	};

	static defaultProps = {
		creative: undefined,
	};

	componentDidMount() {
		const { dispatch, match } = this.props;

		dispatch(callApi(getCreative.id, { id: match.params.id }));
	}

	render() {
		const { getCreativeAPIStatus, getAllGamesAPIStatus, creative } = this.props;

		const name = creative ? creative.name : '';

		const apiStatus = combinedApiStatus(getCreativeAPIStatus, getAllGamesAPIStatus);

		return (
			<>
				<Title>
					{name}
					{' '}
					- Edit
				</Title>
				{apiStatus.pending ? (
					<div>Loading...</div>
				) : apiStatus.error ? (
					<div>Error</div>
				) : !creative ? (
					<div>Config not found</div>
				) : (
					<CreativesEditor
						creative={creative}
					/>
				)}
			</>
		);
	}
}

export default connect((state, { match }) => ({
	creative: selectCreative(state, match.params.id),
	getCreativeAPIStatus: selectAPIStatus(state, getCreative.id),
	getAllGamesAPIStatus: selectAPIStatus(state, getAllGames.id),
}))(CreativesEdit);
