import React from 'react';

import Title from 'app/components/ui/Title';
import BulkHouseAdEditor from 'app/components/editors/BulkHouseAdEditor';

export default class BulkHouseAdsAdd extends React.PureComponent {
	render() {
		return (
			<>
				<Title>Add Bulk houseads</Title>
				<BulkHouseAdEditor />
			</>
		);
	}
}
