import { apiSuccess } from 'app/actions/api';
import { getSites } from 'app/epics/sites';

export const initialState = {};

export default function sitesReducer(state = initialState, action) {
	if (action.type === apiSuccess.type) {
		if (action.id === getSites.id) {
			return action.response;
		}
	}

	return state;
}
