/* eslint-disable no-alert */
import PT from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Fuse from 'fuse.js';

import css from 'app/styles/game-data-table.css';
import Picture from 'app/components/ui/Picture';
import { callApi } from 'app/actions/api';
import Title from 'app/components/ui/Title';
import DataTable from 'app/components/ui/DataTable';
import * as types from 'app/types';
import { getGamesInSeries } from 'app/epics/series';
import { selectGamesInSeries } from 'app/selectors/series';
import { selectAPIStatus } from 'app/selectors/api';

export class SeriesView extends React.PureComponent {
	static propTypes = {
		dispatch: PT.func.isRequired,
		match: PT.shape({
			params: PT.shape({
				id: PT.string.isRequired,
			}).isRequired,
		}).isRequired,
		games: PT.arrayOf(types.categorySummary).isRequired,
		seriesApiStatus: types.apiStatus.isRequired,
	};

	static columns = [
		{
			name: 'Image',
			selector: row => row.image,
			cell: row => (
				<Picture
					alt={row.slug}
					className={css.image}
					image={row.image}
					size={[30, 30]}
				/>
			),
			sortable: true,
			width: '90px',
		},
		{
			selector: row => row.id,
			name: 'ID',
			width: '200px',
			sortable: true,
		},
		{
			selector: row => row.title,
			name: 'Title',
			width: '400px',
			sortable: true,
		},
	];

	componentDidMount() {
		const { dispatch, match } = this.props;

		const id = parseInt(match.params.id, 10);
		dispatch(callApi(getGamesInSeries.id, { id }));
	}

	render() {
		const { seriesApiStatus, games } = this.props;

		const searchIndex = new Fuse(games, {
			caseSensitive: false,
			shouldSort: true,
			threshold: 0.2,
			distance: 100,
			findAllMatches: true,
			useExtendedSearch: true,
			keys: [
				'id',
				'title',
			],
		});

		return (
			<>
				<Title>Series</Title>
				<DataTable
					columns={SeriesView.columns}
					data={seriesApiStatus.pending ? [] : games}
					searchIndex={searchIndex}
					apiStatus={seriesApiStatus}
				/>
			</>
		);
	}
}

export default connect(state => ({
	games: selectGamesInSeries(state),
	seriesApiStatus: selectAPIStatus(state, getGamesInSeries.id),
	addSeriesAPIStatus: selectAPIStatus(state, getGamesInSeries.id),
}))(SeriesView);
