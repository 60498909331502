import { apiSuccess } from 'app/actions/api';
import { getSeries, getGamesInSeries } from 'app/epics/series';

export const initialState = {
	all: [],
	games: [],
};

export default function seriesReducer(state = initialState, action) {
	if (action.type === apiSuccess.type) {
		if (action.id === getSeries.id) {
			return {
				...state,
				all: action.response,
			};
		}
		if (action.id === getGamesInSeries.id) {
			return {
				...state,
				games: action.response,
			};
		}
	}

	return state;
}
