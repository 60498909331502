import React from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import IconCube from 'app/components/icons/IconCube';
import IconController from 'app/components/icons/IconController';
import IconLink from 'app/components/icons/IconLink';
import IconLocalisation from 'app/components/icons/IconLocalisation';
import IconAds from 'app/components/icons/IconAds';
import IconRedirects from 'app/components/icons/IconRedirects';
import IconSeries from 'app/components/icons/IconSeries';

import Logo from 'app/components/ui/Logo';
import { selectPageNum } from 'app/selectors/page';
import { selectUser } from 'app/selectors/session';
import classNames from 'app/utils/classNames';
import css from 'app/styles/sidebar.css';
import * as types from 'app/types';

const navLinks = [
	{
		label: 'Dashboard',
		exact: true,
		path: '/',
		svg: (
			<IconCube />
		),
	},
	{
		label: 'Games',
		path: '/games',
		svg: (
			<IconController />
		),
	},
	{
		label: 'Categories',
		path: '/categories',
		svg: (
			<IconLink />
		),
	},
	{
		label: 'Series',
		path: '/series',
		svg: (
			<IconSeries />
		),
	},
	{
		label: 'Localizations',
		path: '/localisations',
		svg: (
			<IconLocalisation />
		),
	},
	{
		label: 'Redirects',
		path: '/redirects',
		svg: (
			<IconRedirects />
		),
	},
	{
		label: 'Ads',
		path: '/ads',
		svg: (
			<IconAds />
		),
	},
];

export class Sidebar extends React.Component {
	static propTypes = {
		pageNum: PT.number.isRequired,
		user: types.user.isRequired,
	};

	render() {
		const { pageNum, user } = this.props;

		const backgrounds = [css.hearts, css.diamonds, css.blocks, css.coins];
		const bg = backgrounds[(pageNum + 1) % backgrounds.length];

		return (
			<div className={classNames(css.sidebar, bg)}>
				<Logo />
				<nav className={css.nav}>
					{navLinks.map(item => (
						<NavLink
							exact={item.exact}
							key={item.label}
							className={css.item}
							to={item.path}
							activeClassName={css.selected}
						>
							{item.svg}
							<span className={css.label}>{item.label}</span>
						</NavLink>
					))}
				</nav>
				<div className={css.user}>
					<figure className={css.avatar}>
						<img src={user.picture} alt={user.name} />
						<span>{user.name}</span>
					</figure>
				</div>
				<div className={css.version}>
					v
					{process.env.PKG_VERSION}
				</div>
			</div>
		);
	}
}

export default connect(state => ({
	pageNum: selectPageNum(state),
	user: selectUser(state),
}))(Sidebar);
