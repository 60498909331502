import React from 'react';
import PT from 'prop-types';
import Form from 'react-jsonschema-form';

import { creativeConfig, uiCreativeConfig } from 'app/forms/creative';
import gameSelectorWidget from 'app/components/forms/widgets/GameSelectorWidget';
import creativePreviewWidget from 'app/components/forms/widgets/CreativePreviewWidget';
import css from 'app/styles/forms/form.css';
import Button from 'app/components/ui/Button';
import * as types from 'app/types';

export default class CreativeForm extends React.Component {
	static propTypes = {
		onSubmit: PT.func.isRequired,
		creative: types.creative,
	};

	static defaultProps = {
		creative: undefined,
	};

	render() {
		const { creative, onSubmit } = this.props;

		return (
			<div>
				<Form
					schema={creativeConfig()}
					formData={creative}
					uiSchema={uiCreativeConfig}
					noValidate
					onSubmit={onSubmit}
					widgets={{
						gameSelectorWidget,
						creativePreviewWidget,
					}}
				>
					<Button submit size="normal" className={css.right}>Save</Button>
				</Form>
			</div>
		);
	}
}
