import PT from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Collection } from 'react-virtualized';

import { callApi } from 'app/actions/api';
import ListTile from 'app/components/ui/ListTile';
import InlineSearch from 'app/components/ui/InlineSearch';
import { addCategoryGameItem, removeCategoryGameItem } from 'app/actions/categories';
import Button from 'app/components/ui/Button';
import { selectAPIStatus } from 'app/selectors/api';
import css from 'app/styles/editor.css';
import * as types from 'app/types';
import { getCategory, getCategoryGames, saveCategoryGames } from 'app/epics/categories';

const SortableCollection = SortableContainer(Collection, { withRef: true });
const SortableTile = SortableElement(({ key, style, ...props }) => (
	<div key={key} style={style}>
		<ListTile {...props} />
	</div>
));

const cellSizeAndPositionGetter = ({ index }) => ({
	width: 132,
	height: 132,
	x: 16 + ((index % 6) * 148),
	y: 16 + (Math.floor(index / 6) * 148),
});

export class CategoryGamesEditor extends React.PureComponent {
	static propTypes = {
		category: types.category.isRequired,
		dispatch: PT.func.isRequired,
		saveApiStatus: types.apiStatus.isRequired,
		categoryGames: PT.arrayOf(types.game),
	};

	static defaultProps = {
		categoryGames: [],
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { dispatch, category } = this.props;

		if (category.id !== nextProps.category.id) {
			dispatch(callApi(getCategory.id, { id: nextProps.category.id }));
			dispatch(callApi(getCategoryGames.id, { id: nextProps.category.id }));
		}
	}

	cellRenderer = ({ index, key, style }) => (
		<SortableTile
			data={this.props.categoryGames[index]}
			index={index}
			key={key}
			onRemove={this.createHandleRemoveItem(index)}
			style={style}
			disabled
		/>
	);

	createHandleAddItem = item => {
		const { dispatch, category } = this.props;

		dispatch(addCategoryGameItem({ id: category.id, item }));
	};

	createHandleRemoveItem = index => () => {
		const { dispatch, category } = this.props;
		dispatch(removeCategoryGameItem({ id: category.id, index }));
	};

	handleSubmit = ev => {
		ev.preventDefault();
		const { dispatch, category, categoryGames } = this.props;
		dispatch(callApi(saveCategoryGames.id, { id: category.id, games: categoryGames }));
	};

	render() {
		const { saveApiStatus, categoryGames } = this.props;
		const { error, success } = saveApiStatus;

		const categoryGameIDs = categoryGames.map(game => game.id);

		return (
			<form onSubmit={this.handleSubmit}>
				{success && (<h3 className={css.success}>Stored in the database!</h3>)}

				{!success && (
					<>
						<InlineSearch
							onSelect={this.createHandleAddItem}
							filterIDs={categoryGameIDs}
						/>

						<SortableCollection
							axis="xy"
							cellCount={categoryGames.length}
							cellRenderer={this.cellRenderer}
							cellSizeAndPositionGetter={cellSizeAndPositionGetter}
							className={css.list}
							height={904}
							width={904}
							distance={10}
							items={categoryGames}
						/>
						<div className={css.footer}>
							<Button primary submit>Save</Button>
							{!!error && (
								<p className={css.error}><strong>{error}</strong></p>
							)}
						</div>
					</>
				)}
			</form>
		);
	}
}

export default connect(state => ({
	saveApiStatus: selectAPIStatus(state, saveCategoryGames.id),
}))(CategoryGamesEditor);
