/* eslint-disable jsx-a11y/media-has-caption */
import PT from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { callApi } from 'app/actions/api';
import { storeCreative } from 'app/epics/creatives';
import { selectAPIStatus } from 'app/selectors/api';
import css from 'app/styles/form.css';
import * as types from 'app/types';
import CreativeForm from 'app/components/forms/CreativeForm';
import { displayFormats, videoFormats } from 'app/forms/creative';

export class CreativesEditor extends React.PureComponent {
	static propTypes = {
		dispatch: PT.func.isRequired,
		creative: types.creative,
		storeAPIStatus: types.apiStatus.isRequired,
	};

	static defaultProps = {
		creative: {},
	};

	constructor(props) {
		super(props);

		this.state = {
			stateCreative: undefined,
		};
	}

	handleSubmitCreative = data => {
		const { dispatch } = this.props;

		const creative = data.formData;
		this.setState({ stateCreative: { ...creative } });

		if (Object.keys(displayFormats).includes(creative.type)) {
			creative.asset = creative.asset_display;
		} else if (Object.keys(videoFormats).includes(creative.type)) {
			creative.asset = creative.asset_video;

			// this is hacky, but we don't have h264 codec support on the backend
			creative.video_asset_duration = Math.ceil(document.getElementById('creativeVideoPreview')?.duration || 0);
		} else {
			creative.asset = creative.asset_imvita;
		}

		// clean before uploading to prevent additional bytes on the transfer
		creative.asset_display = undefined;
		creative.asset_video = undefined;
		creative.asset_imvita = undefined;

		dispatch(callApi(storeCreative.id, creative));
	};

	render() {
		const { creative, storeAPIStatus } = this.props;
		const { stateCreative } = this.state;

		return (
			<div>
				{storeAPIStatus.error && (
					<p className={css.error}>
						<strong>{storeAPIStatus.error}</strong>
					</p>
				)}
				{storeAPIStatus.pending && (
					<div>Submitting...</div>
				)}
				{storeAPIStatus.success
					&& (
						<div>
							Good news! Your update was stored in the database!
							{' '}
							<Link to="/ads/creatives">Back to Creatives</Link>
							<br />
							<br />
							Do note that if you uploaded a video it might take up to 10min for it to be processed!
						</div>
					)}
				{!storeAPIStatus.success
					&& (
						<CreativeForm
							creative={stateCreative || creative}
							onSubmit={this.handleSubmitCreative}
						/>
					)}
			</div>
		);
	}
}

export default connect(state => ({
	storeAPIStatus: selectAPIStatus(state, storeCreative.id),
}))(CreativesEditor);
