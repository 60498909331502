import React from 'react';
import PT from 'prop-types';

import css from 'app/styles/ui/title.css';

class Title extends React.PureComponent {
	static propTypes = {
		children: PT.node.isRequired,
	};

	render() {
		const { children } = this.props;

		return (
			<div className={css.title}>
				<h1>{children}</h1>
			</div>
		);
	}
}

export default Title;
