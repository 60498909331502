import { apiEpic } from 'app/utils/api';

export const getSeries = apiEpic(
	'series',
	api => api('/secure/series'),
);

export const deleteSeries = apiEpic(
	'series/delete',
	(api, data) => api('/secure/series', {
		body: data,
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
		},
	}),
);

export const addSeries = apiEpic(
	'series/add',
	(api, data) => api('/secure/series', {
		body: data,
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
	}),
);

export const getGamesInSeries = apiEpic(
	'series/games',
	(api, { id }) => api(`/secure/series/${id}/games`),
);
