import React from 'react';

import Title from 'app/components/ui/Title';
import CategoryEditor from 'app/components/editors/CategoryEditor';

class CategoryAdd extends React.PureComponent {
	render() {
		return (
			<>
				<Title>Add Category</Title>
				<CategoryEditor />
			</>
		);
	}
}

export default CategoryAdd;
