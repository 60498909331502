import React from 'react';
import { NavLink } from 'react-router-dom';

import Title from 'app/components/ui/Title';
import IconAds from 'app/components/icons/IconAds';
import IconHouse from 'app/components/icons/IconHouse';
import css from 'app/styles/pages/adsoverview.css';

class AdsOverview extends React.PureComponent {
	render() {
		return (
			<>
				<Title>Advertisements</Title>

				<div className={css.container}>
					<NavLink
						className={css.item}
						to="/ads/adstxt"
					>
						<IconAds />
						<span>Ads.txt</span>
					</NavLink>
					<NavLink
						className={css.item}
						to="/ads/adsettings"
					>
						<IconAds />
						<span>Ad Settings</span>
					</NavLink>
					<NavLink
						className={css.item}
						to="/ads/creatives"
					>
						<IconHouse />
						<span>Creatives</span>
					</NavLink>
				</div>
			</>
		);
	}
}

export default AdsOverview;
