import PT from 'prop-types';
import React from 'react';

import css from 'app/styles/ui/picture.css';
import classNames from 'app/utils/classNames';
import * as types from 'app/types';

export default class Picture extends React.PureComponent {
	static propTypes = {
		alt: PT.string,
		className: PT.string,
		classNamePadding: PT.string,
		size: PT.arrayOf(PT.number).isRequired,
		image: types.image.isRequired,
	};

	static defaultProps = {
		alt: undefined,
		className: undefined,
		classNamePadding: undefined,
	};

	render() {
		const { alt, className, classNamePadding, image, size } = this.props;
		const classes = classNames(css.picture, className);
		const classesPad = classNames(css.padding, classNamePadding);
		const [width, height] = size;
		const padding = (height / width) * 100;

		const src = `https://img.poki-cdn.com/cdn-cgi/image/width=${width},height=${height},fit=cover,f=auto/${image.path}`;

		return (
			<div className={classes}>
				<div className={classesPad} style={{ paddingBottom: `${padding}%` }} />
				<img alt={alt} src={`${src}`} />
			</div>
		);
	}
}
