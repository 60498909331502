export function bulkHouseAdConfig() {
	return {
		type: 'object',
		title: 'Bulk House-ad config',
		required: ['name'],
		properties: {
			name: {
				type: 'string',
				title: 'Base name',
				minLength: 3,
			},
			game_id: {
				type: 'number',
				title: 'Represents game',
			},
		},
	};
}

export const uiBulkHouseAdConfig = {
	game_id: {
		'ui:widget': 'gameSelectorWidget',
	},
};
