import React from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';

import { callApi } from 'app/actions/api';
import { getAllGames } from 'app/epics/games';
import { getCategory, getCategoryGames } from 'app/epics/categories';
import { selectCategory, selectCategoryGames } from 'app/selectors/categories';
import { selectAPIStatus } from 'app/selectors/api';
import Title from 'app/components/ui/Title';
import CategoryGamesEditor from 'app/components/editors/CategoryGamesEditor';
import { combinedApiStatus } from 'app/utils/api';

import * as types from 'app/types';

class CategoryGamesEdit extends React.PureComponent {
	static propTypes = {
		dispatch: PT.func.isRequired,
		category: types.category,
		categoryGames: PT.arrayOf(types.category),
		getCategoryAPIStatus: types.apiStatus.isRequired,
		getAllGamesAPIStatus: types.apiStatus.isRequired,
		match: PT.shape({
			params: PT.shape({
				id: PT.string.isRequired,
			}).isRequired,
		}).isRequired,
	};

	static defaultProps = {
		category: null,
		categoryGames: [],
	};

	componentDidMount() {
		const { dispatch, match } = this.props;

		const id = parseInt(match.params.id, 10);
		dispatch(callApi(getCategory.id, { id }));
		dispatch(callApi(getCategoryGames.id, { id }));
	}

	render() {
		const { getCategoryAPIStatus, getAllGamesAPIStatus, category, categoryGames } = this.props;

		const englishTitle = category && category && category.titles ? category.titles.find(t => t.lang === 'en').content : '';

		const apiStatus = combinedApiStatus(getCategoryAPIStatus, getAllGamesAPIStatus);

		return (
			<>
				<Title>
					{englishTitle}
					{' '}
					- Edit
				</Title>
				{apiStatus.pending ? (
					<div>Loading...</div>
				) : apiStatus.error ? (
					<div>Error</div>
				) : !category ? (
					<div>Category not found</div>
				) : (
					<CategoryGamesEditor
						category={category}
						categoryGames={categoryGames}
					/>
				)}
			</>
		);
	}
}

export default connect((state, { match }) => ({
	category: selectCategory(state, match.params.id),
	categoryGames: selectCategoryGames(state, match.params.id),
	getCategoryAPIStatus: selectAPIStatus(state, getCategory.id),
	getAllGamesAPIStatus: selectAPIStatus(state, getAllGames.id),
}))(CategoryGamesEdit);
