import { isoToLang } from 'app/utils/lang';

export function translationsSchema({ titlesEditAllowed, shortTitleEditAllowed }) {
	return {
		definitions: {
			translation: {
				type: 'object',
				properties: {
					lang: {
						type: 'string',
						anyOf: Object.entries(isoToLang).map(([key, value]) => ({
							type: 'string',
							enum: [key],
							title: value,
						})),
					},
					content: {
						type: 'string',
					},
				},
			},
		},

		// Our translation form
		type: 'object',
		required: [],
		description: '!!! Make sure you always add the "en" translation and don\'t reuse slugs !!!',
		properties: {
			...(titlesEditAllowed ? {
				titles: {
					type: 'array',
					title: 'Titles',
					items: {
						$ref: '#/definitions/translation',
					},
				},
			} : {}),
			slugs: {
				type: 'array',
				title: 'Slugs',
				description: 'CAUTION: These are url\'s under which the games are available on our sites',
				items: {
					$ref: '#/definitions/translation',
				},
			},
			descriptions: {
				type: 'array',
				title: 'Description',
				items: {
					$ref: '#/definitions/translation',
				},
			},
			...(shortTitleEditAllowed ? {
				short_titles: {
					type: 'array',
					title: 'Short Titles',
					items: {
						$ref: '#/definitions/translation',
					},
				},
			} : {}),
		},
	};
}

export const uiTranslationSchema = ({ titlesEditAllowed, shortTitleEditAllowed }) => (
	{
		...(titlesEditAllowed ? {
			titles: {
				items: {
					content: {
						'ui:widget': 'textarea',
					},
				},
				'ui:options': {
					addable: true,
					orderable: false,
					removable: true,
				},
			},
		} : {}),
		slugs: {
			items: {
				content: {
					'ui:widget': 'textarea',
				},
			},
			'ui:options': {
				addable: true,
				orderable: false,
				removable: true,
			},
		},
		descriptions: {
			items: {
				content: {
					'ui:widget': 'textarea',
				},
			},
			'ui:options': {
				addable: true,
				orderable: false,
				removable: true,
			},
		},
		...(shortTitleEditAllowed ? {
			short_titles: {
				type: 'array',
				title: 'Short Titles',
				items: {
					$ref: '#/definitions/translation',
				},
				'ui:options': {
					addable: true,
					orderable: false,
					removable: true,
				},
			},
		} : {}),
	}
);
