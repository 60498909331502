import { createSelector } from 'reselect';

export function selectMedias(state) {
	return state.medias?.data || {};
}

export const selectMediasByID = createSelector(
	(state, id) => state.medias?.data?.[id],

	data => data,
);

export const selectMediaUploadStatus = (state, id) => state.medias?.uploaded?.[id] || {};
