import { ajax } from 'rxjs/ajax';
import { apiEpic, SERVICE_ENV } from 'app/utils/api';
import { empty } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

export const getAllCreatives = apiEpic(
	'creatives/all',
	api => api('/secure/ads/creative'),
);

export const getCreative = apiEpic(
	'creatives/get',
	(api, { id }) => api(`/secure/ads/creative/${id}`),
);

export const storeCreative = apiEpic(
	'creatives/storeCreative',
	(api, data) => {
		const apiUrls = {
			acceptance: 'https://video-compressor-acceptance.poki.io',
			production: 'https://video-compressor.poki.io',
			local: 'https://video-compressor-acceptance.poki.io',
		};
		const apiUrl = apiUrls[SERVICE_ENV] || apiUrls.acceptance;

		if (data.type !== '640x360v' || (data.type === '640x360v' && typeof data?.asset === 'string' && data?.asset?.startsWith('http'))) {
			// Display ads are directly uploaded to the backend
			// Video ads that haven't been updated are directly uploaded
			return api('/secure/ads/creative', {
				body: data,
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
			});
		}

		// Video ads need to be uploaded to the video-compressor
		return api(`${apiUrl}/v1/upload`, {
			body: {
				uploader_id: data.email,
				source_filename: 'ad.mp4', // unknown due to form setup
				source_filesize: data.asset.length,
				type: 'advertisement',
			},
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
		}).pipe(
			take(1),
			switchMap(({ status, response }) => {
				if (status === 200) {
					// Now we upload the actual file
					return ajax({
						url: response.upload_url,
						method: 'PUT',
						body: data.asset,
						headers: {
							'Content-Type': 'video/mp4',
						},
					}).pipe(
						switchMap(() => {
							data.compressor_id = response.id;
							data.asset = '';

							return api('/secure/ads/creative', {
								body: data,
								method: 'POST',
								headers: {
									'Content-Type': 'application/json',
								},
							});
						}),
					);
				}

				return empty();
			}),
		);
	},
);
