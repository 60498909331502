import PT from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { callApi } from 'app/actions/api';
import { searchRedirects, deleteRedirects, addRedirects } from 'app/epics/redirects';
import { selectAPIStatus } from 'app/selectors/api';
import { selectRedirects } from 'app/selectors/redirects';
import Title from 'app/components/ui/Title';
import Button from 'app/components/ui/Button';
import css from 'app/styles/ui/redirects.css';
import * as d3 from 'd3';
import * as types from 'app/types';

export class RedirectsList extends React.PureComponent {
	static propTypes = {
		dispatch: PT.func.isRequired,
		redirects: PT.arrayOf(types.redirect),
		searchRedirectsAPIStatus: types.apiStatus.isRequired,
		deleteRedirectsAPIStatus: types.apiStatus.isRequired,
	};

	static defaultProps = {
		redirects: [],
	};

	constructor(props) {
		super(props);

		this.d3TimeFormat = d3.timeFormat('%B %d, %Y');

		this.state = {
			data: [],
			sourceURL: '',
			targetURL: '',
			selectedRedirects: [],
		};
	}

	UNSAFE_componentWillReceiveProps({ redirects }) {
		if (redirects !== this.state.data) {
			this.setState({
				data: redirects,
			});
		}
	}

	handleChangeQuery = event => {
		const { target } = event;
		const { name } = target;
		this.setState({
			[name]: target.value,
			selectedRedirects: [],
		}, this.handleSearch);
	};

	handleSearch = () => {
		const { dispatch } = this.props;
		const { sourceURL, targetURL } = this.state;
		dispatch(callApi(searchRedirects.id, {
			sourceURL,
			targetURL,
		}));
	};

	handleSelect = event => {
		const { target } = event;
		const { checked } = target;
		let { selectedRedirects } = this.state;
		const sourceURL = target.getAttribute('data-sourceurl');
		const siteID = parseInt(target.getAttribute('data-siteid'), 10);
		const countryID = target.getAttribute('data-countryid');

		// Push to selected redirects
		if (checked) {
			selectedRedirects.push({
				source_url: sourceURL,
				site_id: siteID,
				country_id: countryID,
			});
		} else {
			// remove from selected redirects
			selectedRedirects = selectedRedirects.filter(redirect => redirect.source_url !== sourceURL && redirect.site_id !== siteID && redirect.country_id !== countryID);
		}

		this.setState({
			...selectedRedirects,
		});
	};

	handleDeleteSelected = () => {
		const { dispatch } = this.props;
		const { selectedRedirects } = this.state;

		if (!selectedRedirects) return;

		// eslint-disable-next-line no-restricted-globals, no-alert
		if (confirm(`Are you sure that you want to delete following items: ${JSON.stringify(selectedRedirects)}`)) {
			dispatch(callApi(deleteRedirects.id, selectedRedirects));

			this.handleSearch();
		}
	};

	handleEditTargetURL = redirect => {
		const { dispatch } = this.props;
		// eslint-disable-next-line no-alert
		const targetURL = prompt('Please enter new Target URL', redirect.target_url);
		if (targetURL && targetURL !== redirect.target_url) {
			const editedRedirect = {
				site_id: redirect.site_id,
				country_id: redirect.country_id,
				source_url: redirect.source_url,
				target_url: targetURL,
			};
			dispatch(callApi(addRedirects.id, [editedRedirect]));

			this.handleSearch();
		}
	};

	parseDate = date => {
		if (!date || date === '0001-01-01T00:00:00Z') return '';
		return this.d3TimeFormat(new Date(date));
	};

	render() {
		const { searchRedirectsAPIStatus, deleteRedirectsAPIStatus } = this.props;
		const { data, sourceURL, targetURL } = this.state;

		const deleteBtnText = deleteRedirectsAPIStatus.pending ? 'Deleting...' : 'Delete selected redirects';

		return (
			<div>
				<header className={css.header}>
					<Title>Redirects</Title>
					<Button to="/redirects/add">Add redirects</Button>
				</header>
				<div className={css.queryWrap}>
					<input
						onChange={this.handleChangeQuery}
						placeholder="Source URL / From"
						type="search"
						name="sourceURL"
						value={sourceURL}
						className={css.query}
					/>
					<input
						onChange={this.handleChangeQuery}
						placeholder="Target URL / To"
						type="search"
						name="targetURL"
						value={targetURL}
						className={css.query}
					/>
				</div>
				{searchRedirectsAPIStatus.pending ? (
					<p>Loading...</p>
				) : searchRedirectsAPIStatus.error ? (
					<p>Error</p>
				) : data.length ? (
					<div>
						<table className={css.redirectTable}>
							<thead>
								<tr>
									{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
									<th width="2%" />
									<th width="5%">Site ID</th>
									<th width="5%">GEO</th>
									<th width="30%">Source URL / From</th>
									<th width="30%">Target URL / To</th>
									<th>Updated at</th>
									<th>Updated by</th>
									<th>Actions</th>
								</tr>
							</thead>
							<tbody>
								{data.map(redirect => (
									<tr key={redirect.source_url + redirect.site_id + redirect.country_id}>
										<td><input type="checkbox" data-sourceurl={redirect.source_url} data-siteid={redirect.site_id} data-countryid={redirect.country_id} onChange={this.handleSelect} /></td>
										<td>{redirect.site_id}</td>
										<td>{redirect.country_id}</td>
										<td>{redirect.source_url}</td>
										<td>{redirect.target_url}</td>
										<td>{this.parseDate(redirect.updated_at)}</td>
										<td>{redirect.updated_by}</td>
										<td><button type="button" onClick={() => this.handleEditTargetURL(redirect)}>Edit target URL</button></td>
									</tr>
								))}
							</tbody>
						</table>
						<Button size="normal" onClick={this.handleDeleteSelected}>{deleteBtnText}</Button>
						{deleteRedirectsAPIStatus.error && (
							<p className={css.error}>ERROR while deleting!</p>
						)}
					</div>
				) : <p>Nothing to show. Try to search for something 🧐</p>}
			</div>
		);
	}
}

export default connect(state => ({
	searchRedirectsAPIStatus: selectAPIStatus(state, searchRedirects.id),
	deleteRedirectsAPIStatus: selectAPIStatus(state, deleteRedirects.id),
	redirects: selectRedirects(state),
}))(RedirectsList);
