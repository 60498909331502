import { apiEpic } from 'app/utils/api';

export const getAdSettings = apiEpic(
	'adsettings/get',
	api => api('/secure/ads/settings', { responseType: 'json' }),
);

export const updateAdSettings = apiEpic(
	'adsettings/update',
	(api, { data }) => api('/secure/ads/settings', {
		body: data,
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json; charset=utf-8',
		},
	}),
);
