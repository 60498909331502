import PT from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Title from 'app/components/ui/Title';
import AdSettingsForm from 'app/components/forms/AdSettingsForm';
import * as types from 'app/types';
import { callApi } from 'app/actions/api';
import { getAdSettings, updateAdSettings } from 'app/epics/adsettings';
import { selectAPIStatus } from 'app/selectors/api';
import { selectAdSettings } from 'app/selectors/adsettings';

const transformCountryData = data => {
	if (data.bidder_limitation && data.bidder_limitation.length === 0) return data;

	const output = {};

	data.bidder_limitation.forEach(element => {
		const { country, video, display } = element;

		if (video && video.length === 0 && display && display.length > 0) return;

		output[country] = {};

		if (video && video.length > 0) {
			output[country].video = video.reduce((videoAcc, { bidder, limit }) => {
				videoAcc[bidder] = limit / 100;
				return videoAcc;
			}, {});
		}

		if (display && display.length > 0) {
			output[country].display = display.reduce((displayAcc, { bidder, limit }) => {
				displayAcc[bidder] = limit / 100;
				return displayAcc;
			}, {});
		}
	});

	return output;
};

export class AdSettingsEditor extends React.PureComponent {
	static propTypes = {
		dispatch: PT.func.isRequired,
		adSettings: types.adSettings.isRequired,
		getAdSettingsAPIStatus: types.apiStatus.isRequired,
		updateAdSettingsAPIStatus: types.apiStatus.isRequired,
	};

	componentDidMount() {
		const { dispatch } = this.props;
		dispatch(callApi(getAdSettings.id, -1));
	}

	submitForm = ({ formData: data }) => {
		const { dispatch } = this.props;
		const bidderLimitation = transformCountryData(data);

		dispatch(callApi(updateAdSettings.id, {
			data: {
				...data,
				bidder_limitation: JSON.stringify(bidderLimitation),
			},
		}));
	};

	render() {
		const { getAdSettingsAPIStatus, updateAdSettingsAPIStatus } = this.props;
		const { adSettings } = this.props;

		const base = <Title>Ad Settings</Title>;

		if (!getAdSettingsAPIStatus.success) {
			return (
				<>
					{base}
					<div>Fetching...</div>
				</>
			);
		}

		if (updateAdSettingsAPIStatus.pending) {
			return (
				<>
					{base}
					<div>Saving...</div>
				</>
			);
		}

		if (updateAdSettingsAPIStatus.success) {
			return (
				<>
					{base}
					<div>Saved! It takes about 1hour to be reflected on Poki. 👋</div>
				</>
			);
		}

		return (
			<>
				{base}
				<AdSettingsForm
					initialData={adSettings}
					onSubmit={this.submitForm}
				/>
			</>
		);
	}
}

export default connect(state => ({
	adSettings: selectAdSettings(state),
	getAdSettingsAPIStatus: selectAPIStatus(state, getAdSettings.id),
	updateAdSettingsAPIStatus: selectAPIStatus(state, updateAdSettings.id),
}))(AdSettingsEditor);
