import React from 'react';

export default class IconRedirects extends React.PureComponent {
	render() {
		return (
			<svg fill="#000000" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 104 96" enableBackground="new 0 0 104 96" xmlSpace="preserve">
				<path d="M102.08,52.22l-15.836-12c-1.424-1.084-3.4-1.084-4.828,0l-15.836,12c-1.752,1.337-2.096,3.845-0.76,5.597  c1.328,1.752,3.836,2.104,5.596,0.768l9.412-7.132v24.012c0,6.584-5.393,11.933-12,11.933c-6.616,0-12-5.416-12-12.068V20.532  c0-10.992-8.976-19.936-20-19.936s-20,9-20,20.068v22.7l-9.416-7.14c-1.76-1.336-4.268-0.988-5.6,0.772  c-1.332,1.752-0.992,4.26,0.768,5.596l15.832,12c0.716,0.549,1.568,0.816,2.416,0.816c0.848,0,1.7-0.26,2.416-0.813l15.832-12  c1.764-1.336,2.108-3.844,0.772-5.596c-1.332-1.76-3.84-2.112-5.604-0.772l-9.416,7.132v-22.7c0-6.652,5.384-12.068,12-12.068  c6.616,0,12,5.36,12,11.936v54.808c0,11.068,8.972,20.068,20,20.068c11.023,0,20-8.944,20-19.937V51.46l9.416,7.132  c0.732,0.549,1.576,0.813,2.416,0.813c1.216,0,2.412-0.553,3.195-1.584C104.18,56.064,103.836,53.557,102.08,52.22L102.08,52.22z" />
			</svg>
		);
	}
}
