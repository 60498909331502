import React from 'react';

export default class IconCube extends React.PureComponent {
	render() {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 15" fillRule="evenodd">
				<path d="M8 12.382V7.618l4-2v4.764l-4 2zM2 5.618l4 2v4.764l-4-2V5.618zm5-3.5L10.764 4 7 5.882 3.236 4 7 2.118zm6.447.987l-6-3a1 1 0 0 0-.895 0l-6 3C.214 3.275 0 3.621 0 4v7c0 .379.214.725.553.895l6 3a1.001 1.001 0 0 0 .894 0l6-3c.339-.17.553-.516.553-.895V4c0-.379-.214-.725-.553-.895z" />
			</svg>
		);
	}
}
