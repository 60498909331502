import PT from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import css from 'app/styles/ui/button.css';
import classNames from 'app/utils/classNames';

export default class Button extends React.PureComponent {
	static propTypes = {
		children: PT.node.isRequired,
		className: PT.string,
		primary: PT.bool,
		size: PT.oneOf(['big', 'normal', 'small']),
		submit: PT.bool,
		to: PT.string,
		disabled: PT.bool,
		onClick: PT.func,
		forceNativeLink: PT.bool,
	};

	static defaultProps = {
		className: undefined,
		primary: true,
		size: 'big',
		submit: false,
		to: undefined,
		disabled: false,
		onClick: () => {},
		forceNativeLink: false,
	};

	render() {
		const { children, className, disabled, forceNativeLink, primary, onClick, size, submit, to } = this.props;
		const classes = classNames(css.button, className, {
			[css.primary]: primary,
			[css.normal]: size === 'normal',
			[css.small]: size === 'small',
			[css.disabled]: disabled,
		});

		if (to && !disabled) {
			if (forceNativeLink) {
				return <a href={to} className={classes}>{children}</a>;
			}

			return <Link to={to} className={classes}>{children}</Link>;
		}

		return (
			<button onClick={(!disabled && onClick) || (() => {})} className={classes} type={submit && !disabled ? 'submit' : 'button'}>
				{children}
			</button>
		);
	}
}
