import { combineEpics } from 'redux-observable';

import * as apiEpics from 'app/epics/api';
import * as categoriesEpics from 'app/epics/categories';
import * as gamesEpics from 'app/epics/games';
import * as mediasEpics from 'app/epics/medias';
import * as seriesEpics from 'app/epics/series';
import * as sessionEpics from 'app/epics/session';
import * as sitesEpics from 'app/epics/sites';
import * as localisations from 'app/epics/localisations';
import * as adstxtEpics from 'app/epics/adstxt';
import * as adSettingsEpics from 'app/epics/adsettings';
import * as redirectsEpics from 'app/epics/redirects';
import * as creativesEpics from 'app/epics/creatives';

export default combineEpics(
	...Object.values(apiEpics),
	...Object.values(categoriesEpics),
	...Object.values(gamesEpics),
	...Object.values(mediasEpics),
	...Object.values(seriesEpics),
	...Object.values(sessionEpics),
	...Object.values(sitesEpics),
	...Object.values(localisations),
	...Object.values(adstxtEpics),
	...Object.values(adSettingsEpics),
	...Object.values(redirectsEpics),
	...Object.values(creativesEpics),
);
