/* eslint-disable jsx-a11y/media-has-caption */
import PT from 'prop-types';
import React from 'react';

import css from 'app/styles/forms/widgets/creative-preview-widget.css';
import arrayBufferToBase64 from 'app/utils/arrayBufferToBase64';

export default class CreativePreviewWidget extends React.Component {
	static propTypes = {
		onChange: PT.func.isRequired,
		// eslint-disable-next-line react/forbid-prop-types
		options: PT.object.isRequired,
		value: PT.string,
	};

	static defaultProps = {
		value: undefined,
	};

	constructor(props) {
		super(props);
		this.state = {
			data: undefined,
		};
	}

	handleUpdate = ev => {
		const { type } = this.props.options;

		if (ev?.target?.files[0]) {
			const { onChange } = this.props;

			const reader = new FileReader();
			reader.onloadend = () => {
				this.setState({ data: reader.result });

				if (type === 'video') {
					this.setState({
						data: `data:video/mp4;base64,${arrayBufferToBase64(reader.result)}`,
					});
				}

				onChange(reader.result);
			};

			if (type === 'video') {
				// Binary
				reader.readAsArrayBuffer(ev.target.files[0]);
			} else {
				// base64
				reader.readAsDataURL(ev.target.files[0]);
			}
		}
	};

	render() {
		const { data } = this.state;
		const { type } = this.props.options;
		const { value } = this.props;

		// Data preview conversion from ArrayBuffer to DataUrl seems not to work
		return (
			<div>
				<input name="photo" type="file" accept={type === 'display' ? 'image/*' : 'video/mp4'} onChange={this.handleUpdate} />
				<br />
				{type === 'display' && (<img className={css.fill} src={data || value} alt="creative" />)}
				{type === 'video' && (<video id="creativeVideoPreview" className={css.fill} src={data || value} autoPlay controls />)}
			</div>
		);
	}
}
