import React from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';

import { callApi } from 'app/actions/api';
import { getCategory } from 'app/epics/categories';
import { selectCategory } from 'app/selectors/categories';
import { selectAPIStatus } from 'app/selectors/api';
import Title from 'app/components/ui/Title';
import CategoryEditor from 'app/components/editors/CategoryEditor';

import * as types from 'app/types';

class CategoryEdit extends React.PureComponent {
	static propTypes = {
		dispatch: PT.func.isRequired,
		category: types.category,
		match: PT.shape({
			params: PT.shape({
				id: PT.string.isRequired,
			}).isRequired,
		}).isRequired,
		getCategoryAPIStatus: types.apiStatus.isRequired,
	};

	static defaultProps = {
		category: null,
	};

	componentDidMount() {
		const { dispatch, match } = this.props;

		const id = parseInt(match.params.id, 10);
		dispatch(callApi(getCategory.id, { id }));
	}

	render() {
		const { getCategoryAPIStatus, category } = this.props;

		const englishTitle = category && category && category.titles ? category.titles.find(t => t.lang === 'en').content : '';

		return (
			<>
				<Title>
					{englishTitle}
					{' '}
					- Edit
				</Title>
				{getCategoryAPIStatus.pending ? (
					<div>Loading...</div>
				) : getCategoryAPIStatus.error ? (
					<div>Error</div>
				) : !category ? (
					<div>Category not found</div>
				) : (
					<CategoryEditor
						category={category}
					/>
				)}
			</>
		);
	}
}

export default connect((state, { match }) => ({
	category: selectCategory(state, match.params.id),
	getCategoryAPIStatus: selectAPIStatus(state, getCategory.id),
}))(CategoryEdit);
