/* eslint-disable react/no-unstable-nested-components */
import PT from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Fuse from 'fuse.js';

import { getAllGames } from 'app/epics/games';
import DataTable from 'app/components/ui/DataTable';
import Picture from 'app/components/ui/Picture';
import { selectAllGames } from 'app/selectors/games';
import { selectAPIStatus } from 'app/selectors/api';
import * as types from 'app/types';
import css from 'app/styles/game-data-table.css';
import { copyToClipboard } from 'app/utils/copyText';

export class GameDataTable extends React.PureComponent {
	static propTypes = {
		games: PT.arrayOf(types.game).isRequired,
		apiStatus: types.apiStatus.isRequired,
	};

	render() {
		const { games, apiStatus } = this.props;

		const columns = [
			{
				name: 'Image',
				selector: row => row.image,
				cell: row => (
					<Picture
						alt={row.slug}
						className={css.image}
						image={row.image}
						size={[30, 30]}
					/>
				),
				sortable: true,
				width: '90px',
			},
			{
				name: 'Title',
				cell: row => (
					<Link to={`/games/${row.id}/edit`}>{row.title}</Link>
				),
				sortable: true,
			},
			{
				name: 'Slug',
				selector: row => row.slug,
				sortable: true,
			},
			{
				name: 'ID',
				cell: row => (
					<button type="button" onClick={() => { copyToClipboard(row.id); }}>{row.id}</button>
				),
				sortable: true,
				width: '100px',
			},
			{
				name: 'Edit',
				selector: row => row.edit,
				width: '75px',
				cell: row => (
					<Link to={`/games/${row.id}/edit`}>✏️</Link>
				),
				sortable: true,
				ignoreRowClick: true,
				allowOverflow: true,
				button: true,
			},
			{
				name: 'Media',
				selector: row => row.media,
				width: '75px',
				cell: row => (
					<Link to={`/games/${row.id}/media`}>🖼️</Link>
				),
				sortable: true,
				ignoreRowClick: true,
				allowOverflow: true,
				button: true,
			},
			{
				name: 'Categories',
				selector: row => row.categories,
				width: '75px',
				cell: row => (
					<Link to={`/games/${row.id}/categories`}>🗂️</Link>
				),
				sortable: true,
				ignoreRowClick: true,
				allowOverflow: true,
				button: true,
			},
		];

		const searchIndex = new Fuse(games, {
			caseSensitive: false,
			shouldSort: true,
			threshold: 0.3,
			distance: 100,
			findAllMatches: true,
			useExtendedSearch: true,
			keys: [
				'title',
				'slug',
			],
		});

		return (
			<DataTable
				columns={columns}
				data={games}
				searchIndex={searchIndex}
				apiStatus={apiStatus}
			/>
		);
	}
}

export default connect(state => {
	const allGames = selectAllGames(state);

	return {
		games: Object.values(allGames).sort((a, b) => b.id - a.id),
		apiStatus: selectAPIStatus(state, getAllGames.id),
	};
})(GameDataTable);
