import React from 'react';

export default class IconController extends React.PureComponent {
	render() {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fillRule="evenodd">
				<path d="M7 0h2v5H7zM11.5 7h-7C2 7 0 9 0 11.5S2 16 4.5 16h7c2.5 0 4.5-2 4.5-4.5S14 7 11.5 7zM6 12H5v1H4v-1H3v-1h1v-1h1v1h1v1zm4 0h1v1h-1v-1zm1-2h1v1h-1v-1zm1 2h1v1h-1v-1z" />
			</svg>
		);
	}
}
