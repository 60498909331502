/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PT from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import * as types from 'app/types';

import { selectGamesList, selectAllGames } from 'app/selectors/games';
import { selectQuery } from 'app/selectors/search';
import debounce from 'just-debounce';
import { updateQuery } from 'app/actions/search';
import css from 'app/styles/forms/widgets/game-selector-widget.css';

export class GameSelectorWidget extends React.Component {
	static propTypes = {
		dispatch: PT.func.isRequired,
		allGames: PT.objectOf(types.game).isRequired,
		searchGames: PT.arrayOf(types.game).isRequired,
		onChange: PT.func.isRequired,
		query: PT.string.isRequired,
		value: PT.number,
	};

	static defaultProps = {
		value: undefined,
	};

	handleChangeQuery = debounce(query => {
		const { dispatch } = this.props;

		dispatch(updateQuery({ query }));
	}, 200);

	handleClick = gameID => {
		const { dispatch, onChange } = this.props;

		onChange(gameID);
		dispatch(updateQuery({ query: '' }));
	};

	handleClear = () => {
		const { onChange } = this.props;

		onChange();
	};

	render() {
		const { allGames, searchGames, query, value } = this.props;

		let gameList = '';
		if (searchGames.length > 0 && query !== '') {
			gameList = searchGames.map(game => (<li key={`li-${game.id}`} onClick={() => this.handleClick(game.id)}>{game.title}</li>));
		}

		let title = '';
		if (value) {
			// We're looking up the game here to prevent rerenders
			const game = allGames[value];
			if (game) {
				title = `${game.title} (GameID: ${game.id})`;
			} else {
				title = 'Game not found!';
			}
		}

		return (
			<div className={css.container}>
				<b>Currently selected this game: </b>
				{title}
				{' '}
				{title?.length > 0 && (<button type="button" className={css.button} onClick={this.handleClear}>✖️ Unlink</button>)}
				<br />
				<b>Search on game title: </b>
				<input type="text" className={css.textContainer} onChange={ev => this.handleChangeQuery(ev?.target?.value)} />
				<ul>
					{gameList}
				</ul>
			</div>

		);
	}
}

export default connect(state => ({
	allGames: selectAllGames(state),
	searchGames: selectGamesList(state).slice(0, 10),
	query: selectQuery(state),
}))(GameSelectorWidget);
