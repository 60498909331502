import { isoToLang } from 'app/utils/lang';

export function localisationSchema() {
	return {
		// Form
		type: 'array',
		description: '!!! Make sure you always add the "en" localization !!!',
		items: {
			type: 'object',
			properties: {
				lang: {
					type: 'string',
					anyOf: Object.entries(isoToLang).map(([key, value]) => ({
						type: 'string',
						enum: [key],
						title: value,
					})),
				},
				content: {
					type: 'string',
					title: 'Content',
				},
			},
		},

	};
}

export const uiLocalisationchema = {
	items: {
		content: {
			'ui:widget': 'textarea',
		},
	},
	'ui:options': {
		addable: true,
		orderable: false,
		removable: false,
	},
};
