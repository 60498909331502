import { routerReducer as router } from 'react-router-redux';
import { combineReducers } from 'redux';

import api from 'app/reducers/api';
import categories from 'app/reducers/categories';
import search from 'app/reducers/search';
import games from 'app/reducers/games';
import page from 'app/reducers/page';
import session from 'app/reducers/session';
import sites from 'app/reducers/sites';
import series from 'app/reducers/series';
import localisations from 'app/reducers/localisations';
import adstxt from 'app/reducers/adstxt';
import adsettings from 'app/reducers/adsettings';
import redirects from 'app/reducers/redirects';
import creatives from 'app/reducers/creatives';
import medias from 'app/reducers/medias';

export default combineReducers({
	api,
	categories,
	search,
	games,
	page,
	router,
	session,
	sites,
	series,
	localisations,
	adstxt,
	adsettings,
	redirects,
	creatives,
	medias,
});
