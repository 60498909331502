export const displayFormats = {
	'300x250': '300 x 250 - Medium Rectangle',
	'336x280': '336 x 280 - Large Rectangle',
	'160x600': '160 x 600 - Wide Skyscraper',
	'300x600': '300 x 600 - Halfpage / Large Skyscraper',
	'728x90': '728 x 90 - Leaderboard',
	'970x90': '970 x 90 - Large Leaderboard',
	'970x250': '970 x 250 - Billboard',
	'320x50': '320 x 50 - Mobile Banner',
	'320x100': '320 x 100 - Large Mobile Banner',
};

export const videoFormats = {
	'640x360v': '640 x 360v - Video',
};

export const customFormats = {
	imvita: 'Imvita',
};

export function creativeConfig() {
	return {
		type: 'object',
		title: 'Creative Editor',
		required: ['name', 'type'],
		properties: {
			name: {
				type: 'string',
				title: 'Name',
				minLength: 3,
			},
			adm_creative_id: {
				type: 'string',
				title: 'ADM Creative ID',
			},
			click_through_url: {
				type: 'string',
				title: 'Click-through URL',
			},
			start_date: {
				type: [
					'string',
					'null',
				],
				format: 'date',
				title: 'Starts at',
				default: 'active',
			},
			end_date: {
				type: [
					'string',
					'null',
				],
				format: 'date',
				title: 'Ends at',
			},
			type: {
				type: 'string',
				enum: [
					...Object.keys(displayFormats),
					...Object.keys(videoFormats),
					...Object.keys(customFormats),
				],
				enumNames: [
					...Object.values(displayFormats),
					...Object.values(videoFormats),
					...Object.values(customFormats),
				],
			},
			game_id: {
				type: 'number',
				title: 'Represents game',
			},
		},
		dependencies: {
			type: {
				oneOf: [{
					properties: {
						type: {
							type: 'string',
							enum: [
								...Object.keys(displayFormats),
							],
						},
						asset_display: {
							type: 'string',
							title: 'Display creative',
						},
					},
				},
				{
					properties: {
						type: {
							type: 'string',
							enum: [
								...Object.keys(videoFormats),
							],
						},
						asset_video: {
							type: 'string',
							title: 'Video creative',
						},
					},
				},
				{
					properties: {
						type: {
							type: 'string',
							enum: [
								...Object.keys(customFormats),
							],
						},
						asset_imvita: {
							type: 'string',
							title: 'Imvita creative',
						},
					},
				}],
			},
		},
	};
}

export const uiCreativeConfig = {
	asset_imvita: {
		'ui:widget': 'textarea',
	},
	game_id: {
		'ui:widget': 'gameSelectorWidget',
	},
	asset_display: {
		'ui:widget': 'creativePreviewWidget',
		'ui:options': {
			type: 'display',
		},
	},
	asset_video: {
		'ui:widget': 'creativePreviewWidget',
		'ui:options': {
			type: 'video',
		},
	},
};
