import React from 'react';
import PT from 'prop-types';

import css from 'app/styles/ui/category-list.css';
import Button from 'app/components/ui/Button';
import * as types from 'app/types';
import classNames from 'app/utils/classNames';

function CategoryList({ categories, className }) {
	return (
		<ul className={classNames(css.container, className)}>
			{categories.map(({ id, title }) => (
				<li key={id} className={css.item}><Button primary={false} size="small" to={`/category-game/${id}/edit`}>{title}</Button></li>
			))}
		</ul>
	);
}
CategoryList.propTypes = {
	className: PT.string,
	categories: PT.arrayOf(types.categorySummary).isRequired,
};
CategoryList.defaultProps = {
	className: undefined,
};

export default CategoryList;
