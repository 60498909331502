import { createSelector } from 'reselect';
import Fuse from 'fuse.js';

import { selectQuery } from 'app/selectors/search';
import { selectAllGames } from 'app/selectors/games';

const emptyArray = [];

export const selectCategories = createSelector(
	state => state.categories.categories,

	// we only want categories with metadata in them (see category prop-type)
	categories => Object.values(categories).filter(({ id, slug, title }) => ![id, slug, title].includes(undefined)),
);

export const selectConsoleCategories = createSelector(
	state => state.categories.console_categories,
	consoleCategories => Object.values(consoleCategories).filter(({ id, title }) => ![id, title].includes(undefined)),
);

export const selectTopLevelCategories = createSelector(
	state => state.categories.top_level_categories,
	categories => Object.values(categories).filter(({ id, slug, title }) => ![id, slug, title].includes(undefined)),
);

export const selectCategory = (state, id) => (state.categories.category ? state.categories.category[id] : null) || null;

export const selectCategoryGames = createSelector(
	(state, id) => (state.categories.categories[id] ? state.categories.categories[id].games || emptyArray : emptyArray),
	state => selectAllGames(state) || {},

	(gameIds, games) => gameIds.map(gameId => games?.[gameId]).filter(game => game),
);

export const selectCategoryList = createSelector(
	state => selectCategories(state),
	state => selectQuery(state),

	(categories, query) => {
		if (query === '') {
			return categories.sort((a, b) => b.id - a.id).slice(0, 50);
		}

		const fuse = new Fuse(categories, {
			caseSensitive: false,
			shouldSort: true,
			threshold: 0.2,
			distance: 20,
			keys: [
				'id',
				'slug',
				'title',
			],
		});

		return fuse.search(query);
	},
);
