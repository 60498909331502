import React from 'react';
import { Link } from 'react-router-dom';

import css from 'app/styles/ui/logo.css';
import LogoSVG from 'app/components/ui/LogoSVG';

export default class Logo extends React.PureComponent {
	render() {
		return (
			<div className={css.container}>
				<Link to="/" className={css.logo}>
					<LogoSVG />
				</Link>
			</div>
		);
	}
}
