import 'app/styles/base.css';

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import App from 'app/components/pages/App';
import Login from 'app/components/pages/Login';
import AuthCallback from 'app/components/pages/AuthCallback';

export default class Root extends React.Component {
	render() {
		return (
			<Switch>
				<Route path="/auth/callback" exact component={AuthCallback} />
				<Route path="/login/:reason(expired)?" component={Login} />
				<Route path="*" component={App} />
			</Switch>
		);
	}
}
