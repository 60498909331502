import { apiSuccess } from 'app/actions/api';
import { getLocalisations, getLocalisation } from 'app/epics/localisations';

export const initialState = {
	data: [],
	current: {},
};

export default function localisationReducer(state = initialState, action) {
	if (action.type === apiSuccess.type) {
		if (action.id === getLocalisations.id) {
			return {
				...state,
				data: [
					...state.data,
					...Object.keys(action.response).map(key => ({
						id: key,
						title: action.response[key],
					})),
				],
			};
		}

		if (action.id === getLocalisation.id) {
			return {
				...state,
				current: {
					...action.response,
				},
			};
		}
	}

	return state;
}
