import createAction from 'app/utils/createAction';

export const clearAllStatuses = createAction('api/clear_all_statuses');

// Do not use below actions directly, they are generated
export const apiFetch = createAction('api/fetch');
export const apiError = createAction('api/error');
export const apiSuccess = createAction('api/success');

// Use this one instead
export const callApi = (id, options = {}) => apiFetch({ id, options });
