/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/media-has-caption */
import PT from 'prop-types';
import React, { useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { useDropzone } from 'react-dropzone';

import { callApi } from 'app/actions/api';
import { storeCreative } from 'app/epics/creatives';
import { selectAPIStatus } from 'app/selectors/api';
import css from 'app/styles/form.css';
import * as types from 'app/types';
import BulkHouseAdForm from 'app/components/forms/BulkHouseAdForm';
import { displayFormats, videoFormats } from 'app/forms/creative';
import Button from 'app/components/ui/Button';
import arrayBufferToBase64 from 'app/utils/arrayBufferToBase64';

const baseStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '20px',
	margin: '20px 0',
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	backgroundColor: '#fafafa',
	color: '#bdbdbd',
	outline: 'none',
	transition: 'border .24s ease-in-out',
};

const focusedStyle = {
	borderColor: '#2196f3',
};

const acceptStyle = {
	borderColor: '#00e676',
};

const rejectStyle = {
	borderColor: '#ff1744',
};

export class BulkHouseAdEditor extends React.PureComponent {
	static propTypes = {
		dispatch: PT.func.isRequired,
		storeAPIStatus: types.apiStatus.isRequired,
	};

	constructor(props) {
		super(props);

		this.state = {
			name: '',
			files: [],
			ads: {},
		};
	}

	getAds = () => {
		const { ads } = this.state;
		const formats = [...Object.keys(displayFormats), ...Object.keys(videoFormats)];
		const acceptedAds = Object.values(ads).filter(size => formats.includes(size));
		const rejectedAds = Object.values(ads).filter(size => !formats.includes(size));

		return [acceptedAds, rejectedAds];
	};

	handleChange = data => {
		const { name, game_id: gameID } = data?.formData || {};
		this.setState({ name, gameID });
	};

	handleSubmit = () => {
		const { dispatch } = this.props;
		const { name, gameID, files, ads } = this.state;
		const [acceptedAds, rejectedAds] = this.getAds();

		if (rejectedAds.length > 0 && !confirm("Sure about this? You've got rejected formats. We won't upload them!")) {
			return;
		}

		if (acceptedAds.length === 0) {
			alert('No uploaded files, sooooooo (╯°□°)╯︵ ┻━┻');
			return;
		}

		if (!name) {
			alert('Fill in the name');
			return;
		}

		if (!gameID) {
			alert('Fill in the game');
			return;
		}

		for (const [fileName, format] of Object.entries(ads)) {
			const creative = {
				name: `HouseAd - ${name} - ${format}`,
				start_date: '2022-01-01',
				end_date: '2038-01-01',
				type: format,
				game_id: gameID,
			};

			const isVideo = format === '640x360v';
			const reader = new FileReader();
			reader.onloadend = () => {
				creative.asset = reader.result;

				if (isVideo) {
					const video = document.getElementById('hiddenVideo');
					video.ondurationchange = () => {
						creative.video_asset_duration = Math.ceil(video?.duration || 0);

						dispatch(callApi(storeCreative.id, creative));
					};
					video.src = `data:video/mp4;base64,${arrayBufferToBase64(reader.result)}`;
				} else {
					dispatch(callApi(storeCreative.id, creative));
				}
			};

			if (isVideo) {
				// Binary
				reader.readAsArrayBuffer(files.find(file => file.name === fileName));
			} else {
				// base64
				reader.readAsDataURL(files.find(file => file.name === fileName));
			}
		}
	};

	// eslint-disable-next-line react/no-unused-class-component-methods
	dropZone = () => {
		const onDrop = useCallback(acceptedFiles => {
			let videoFound = false;
			acceptedFiles.forEach(file => {
				if (file.type.includes('image/')) {
					const img = new Image();
					img.src = window.URL.createObjectURL(file);
					img.onload = () => {
						this.setState({ ads: { ...this.state.ads, [file.name]: `${img.width}x${img.height}` } });
					};
				} else if (file.type.includes('video/') && !videoFound) {
					this.setState({ ads: { ...this.state.ads, [file.name]: '640x360v' } });
					videoFound = true;
				} else {
					alert('Two videos detected! I\'m unsure what to use, please reload and upload one :)');
				}
			});

			this.setState({ files: acceptedFiles });
		}, []);

		const {
			acceptedFiles,
			getRootProps,
			getInputProps,
			isFocused,
			isDragAccept,
			isDragReject,
		} = useDropzone({ accept: ['image/*', 'video/mp4'], onDrop });

		const style = useMemo(() => ({
			...baseStyle,
			...(isFocused ? focusedStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
		}), [
			isFocused,
			isDragAccept,
			isDragReject,
		]);

		return (
			<section className="container">
				<div {...getRootProps({ style })}>
					<input {...getInputProps()} />
					{
						acceptedFiles?.length === 0
							? (<p>Drag&apos;n drop some files here, or click to select files</p>)
							: (
								<p>
									Received
									{acceptedFiles?.length}
									{' '}
									files. Reload to make changes
								</p>
							)
					}
				</div>
			</section>
		);
	};

	render() {
		const { storeAPIStatus } = this.props;
		const { name } = this.state;
		const [acceptedAds, rejectedAds] = this.getAds();

		return (
			<div>
				{storeAPIStatus.error && (
					<p className={css.error}>
						<strong>{storeAPIStatus.error}</strong>
					</p>
				)}
				{storeAPIStatus.pending && (
					<div>Submitting...</div>
				)}
				{storeAPIStatus.success
					&& (
						<div>
							Good news! Well we think so... We are currently uploading some assets, but have no clue what the status is.
							Just keep this open for 10s plz :)
							<br />
							<br />
							Do note that if you uploaded a video it might take up to 10min for it to be processed!
						</div>
					)}
				{!storeAPIStatus.success
					&& (
						<>
							<BulkHouseAdForm
								onChange={this.handleChange}
							/>

							<this.dropZone />

							Detected formats:
							<ul>
								{acceptedAds.map(format => (
									<li key={format}>
										HouseAd -
										{ name }
										{' '}
										-
										{ format }
									</li>
								))}
							</ul>

							Rejected formats
							<ul>
								{rejectedAds.map(format => (
									<li key={format}>
										HouseAd -
										{ name }
										{' '}
										-
										{ format }
									</li>
								))}
							</ul>

							<Button submit size="normal" className={css.right} onClick={this.handleSubmit}>Save</Button>

							<video
								id="hiddenVideo"
								style={{
									display: 'none',
								}}
							/>
						</>
					)}
			</div>
		);
	}
}

export default connect(state => ({
	storeAPIStatus: selectAPIStatus(state, storeCreative.id),
}))(BulkHouseAdEditor);
