import { createSelector } from 'reselect';
import Fuse from 'fuse.js';

import { selectQuery } from 'app/selectors/search';

const fuse = new Fuse({}, {
	caseSensitive: false,
	shouldSort: true,
	threshold: 0.6,
	distance: 3,
	keys: [
		'id',
		'slug',
		'title',
	],
});

export function selectGame(state, id) {
	return state.games?.data?.[id] || null;
}

export function selectAllGames(state) {
	return state.games?.games || {};
}

export const selectGamesList = createSelector(
	state => selectAllGames(state),
	state => selectQuery(state),

	(games, query) => {
		if (!games || Object.values(games).length === 0) {
			return [];
		}

		const arr = Object.values(games);

		if (query === '') {
			return arr.sort((a, b) => b.id - a.id).slice(0, 50);
		}

		fuse.setCollection(arr);
		return fuse.search(query).slice(0, 50);
	},
);
