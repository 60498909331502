import React from 'react';
import Form from 'react-jsonschema-form';
import PropTypes from 'prop-types';
import { countryNames, isoCountryCodes } from 'app/utils/lang';
import Button from 'app/components/ui/Button';

const bidderEnum = ['appnexus', 'openx', 'ix', 'richaudience', 'onetag', 'rubicon', 'pubmatic', 'sharethrough', 'triplelift', 'adagio', 'rise', 'amazon'];

const schema = {
	type: 'object',
	properties: {
		country_exclusion: {
			type: 'string',
			title: 'Countries without ads (comma separated)',
		},
		blocklist: {
			type: 'string',
			title: 'Blocklist',
		},
		bidder_limitation: {
			title: 'Bidder limitations',
			type: 'array',
			items: {
				type: 'object',
				properties: {
					country: {
						type: 'string',
						enum: isoCountryCodes,
						enumNames: countryNames,
					},
					video: {
						type: 'array',
						items: {
							type: 'object',
							properties: {
								bidder: {
									type: 'string',
									enum: bidderEnum,
								},
								limit: {
									type: 'integer',
									minimum: 1,
									maximum: 100,
								},
							},
							required: ['bidder', 'limit'],
						},
					},
					display: {
						type: 'array',
						items: {
							type: 'object',
							properties: {
								bidder: {
									type: 'string',
									enum: bidderEnum,
								},
								limit: {
									type: 'integer',
									minimum: 1,
									maximum: 100,
								},
							},
							required: ['bidder', 'limit'],
						},
					},
				},
				required: ['country'],
			},
		},
	},
};

const uiSchema = {
	blocklist: {
		'ui:widget': 'textarea',
	},
	bidder_limitation: {
		'ui:description': 'Limits are from 1 to 100. Example setting 5 will mean that 5% of the traffic will be allowed!',
		'ui:options': {
			orderable: false,
		},
		items: {
			country: { 'ui:widget': 'select' },
			video: {
				'ui:options': {
					addable: true,
					orderable: false,
					removable: true,
				},
			},
			display: {
				'ui:options': {
					addable: true,
					orderable: false,
					removable: true,
				},
			},
		},
	},
};

const transformInitialData = data => {
	if (typeof data === 'string') {
		try {
			data = JSON.parse(data);
		} catch (e) {
			console.error('Failed to parse bidderLimitation:', e);
			data = {};
		}
	}

	return Object.entries(data).map(([country, tags]) => ({
		country,
		video: Object.entries(tags.video || {}).map(([bidder, limit]) => ({
			bidder,
			limit: Math.round(limit * 100),
		})),
		display: Object.entries(tags.display || {}).map(([bidder, limit]) => ({
			bidder,
			limit: Math.round(limit * 100),
		})),
	}));
};

function AdSettingsForm({ initialData, onSubmit }) {
	const initialFormData = {
		...initialData,
		bidder_limitation: transformInitialData(initialData.bidder_limitation),
	};

	return (
		<Form
			schema={schema}
			uiSchema={uiSchema}
			noValidate
			formData={initialFormData}
			onSubmit={onSubmit}
		>
			<div />
			<Button primary submit>
				Save ad settings
			</Button>
		</Form>
	);
}

AdSettingsForm.propTypes = {
	initialData: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]).isRequired,
	onSubmit: PropTypes.func.isRequired,
};

export default AdSettingsForm;
