import React from 'react';

import Button from 'app/components/ui/Button';
import css from 'app/styles/pages/login.css';
import * as types from 'app/types';
import { SERVICE_ENV } from 'app/utils/api';

export default class Login extends React.PureComponent {
	static propTypes = {
		match: types.match.isRequired,
	};

	render() {
		const { match: { params } } = this.props;
		const returnURL = `${window.location.protocol}//${window.location.host}/auth/callback`;
		let authURL = `https://auth-acceptance.poki.io/auth/signin/google?service=poki-console&return=${returnURL}`;
		if (SERVICE_ENV === 'production') {
			authURL = `https://auth.poki.io/auth/signin/google?service=poki-console&return=${returnURL}`;
		}

		return (
			<div className={css.wrapper}>
				{params.reason === 'expired' ? <div className={css.reason}>Session expired</div> : null}
				<Button to={authURL} forceNativeLink>Login</Button>
			</div>
		);
	}
}
