import PT from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import * as types from 'app/types';
import { callApi } from 'app/actions/api';
import { getGame } from 'app/epics/games';
import Title from 'app/components/ui/Title';
import { selectGame } from 'app/selectors/games';
import MediaEditor from 'app/components/editors/MediaEditor';
import GameInfo from 'app/components/ui/GameInfo';

export class GameMediaEdit extends React.PureComponent {
	static propTypes = {
		dispatch: PT.func.isRequired,
		game: types.game,
		match: PT.shape({
			params: PT.shape({
				id: PT.string.isRequired,
			}).isRequired,
		}).isRequired,
	};

	static defaultProps = {
		game: {},
	};

	componentDidMount() {
		const { dispatch, match } = this.props;
		const id = parseInt(match.params.id, 10);

		dispatch(callApi(getGame.id, { id }));
	}

	render() {
		const { game, match } = this.props;

		if (!game) {
			return <div>Loading</div>;
		}

		const englishTitle = game?.details?.titles ? game.details.titles.find(t => t.lang === 'en').content : 'No title';
		const image = game?.details?.image;

		const p4dGame = !!(game.details.pokifordevs_game_id && game.details.pokifordevs_game_id !== '');

		const { id } = match.params;
		return (
			<div>
				<GameInfo id={id} image={image} title={englishTitle} />
				<Title>Edit Media</Title>
				<MediaEditor mediaCollectionID={game.details.media_collection_id} disableThumbnailEdit={p4dGame} />
			</div>
		);
	}
}

export default connect((state, { match }) => ({
	game: selectGame(state, match.params.id),
}))(GameMediaEdit);
