import React from 'react';

export default class IconSeries extends React.PureComponent {
	render() {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 500">
				<path
					d="M374.977,9.585c-11.154,0-22.133,1.901-32.639,5.64c-9.824,3.517-20.709,3.517-30.5,0  c-10.439-3.738-21.421-5.64-32.639-5.64c-31.214,0-50.242,12.672-76.863,37.639c-6.923,6.492-117.119,112.635-129.201,125.919  c-28.641,31.491-42.938,49.825-42.938,85.445c0,27.76,11.534,53.602,31.908,72.091v116.675c0,29.582,24.067,53.649,53.649,53.649  h119.953c14.313,0,27.018-5.914,36.8-14.873c0.019-0.017,0.031-0.025,0.049-0.042C312.74,447.14,410.908,345.898,415.54,341.262  c12.589-12.602,25.157-26.196,25.157-45.296V179.305c20.376-18.489,31.91-44.331,31.91-72.089  C472.607,53.377,428.814,9.585,374.977,9.585z M263.229,310.24c-3.992,3.074-6.337,7.828-6.337,12.866v124.249  c0,11.693-9.507,21.2-21.185,21.2H115.754c-11.693,0-21.2-9.507-21.2-21.2V323.105c0-5.038-2.345-9.808-6.354-12.866  c-16.238-12.468-25.555-31.292-25.555-51.652c0-35.933,29.248-65.181,65.182-65.181c7.479,0,14.768,1.251,21.723,3.754  c16.889,6.021,35.49,5.99,52.349,0c6.97-2.487,14.274-3.754,21.706-3.754c35.95,0,65.197,29.248,65.197,65.181  C288.801,278.934,279.484,297.755,263.229,310.24z"
				/>
			</svg>
		);
	}
}
