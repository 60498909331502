/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-shadow */
import PT from 'prop-types';
import React, { Component, useMemo, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ReactModal from 'react-modal';
import { useDropzone } from 'react-dropzone';
import { connect } from 'react-redux';

import * as types from 'app/types';
import { callApi } from 'app/actions/api';
import Button from 'app/components/ui/Button';
import css from 'app/styles/editors/drag-drop-list.css';
import { MediaImageThumbnail, MediaVideoThumbnail, MediaAlternativeThumbnail, MediaLandscapeScreenshot, MediaPortraitScreenshot, MediaMisc, MediaStatusComplete } from 'app/components/editors/util/mediaTypes';
import { uploadMedia, updateMediaOrder, deleteMedia } from 'app/epics/medias';
import { selectMediaUploadStatus } from 'app/selectors/medias';
import { selectUser } from 'app/selectors/session';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: 'none',
	margin: `0 0 ${grid}px 0`,
	borderRadius: '6px',
	border: '2px solid #009cff',
	position: 'relative',

	// change background colour if dragging
	background: isDragging ? '#BAC9DE' : '#F0F5FC',

	// styles we need to apply on draggables
	...draggableStyle,
});

const getListStyle = isDraggingOver => ({
	background: isDraggingOver ? '#EEE' : '#FFF',
	padding: grid,
	width: '96%',
	margin: '0 2%',
	border: '2px solid #BAC9DE',
	borderRadius: '16px',
	minWidth: '250px',
});

const baseStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: '20px',
	margin: '20px 0',
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	backgroundColor: '#fafafa',
	color: '#bdbdbd',
	outline: 'none',
	transition: 'border .24s ease-in-out',
};

const focusedStyle = {
	borderColor: '#2196f3',
};

const acceptStyle = {
	borderColor: '#00e676',
};

const rejectStyle = {
	borderColor: '#ff1744',
};

class MediaDragDropList extends Component {
	static propTypes = {
		dispatch: PT.func.isRequired,
		items: PT.array.isRequired,
		type: PT.string.isRequired,
		mediaCollectionID: PT.string.isRequired,
		uploadStatus: PT.object.isRequired,
		user: types.user.isRequired,
	};

	constructor(props) {
		super(props);
		this.state = {
			items: props?.items || [],
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.items.length !== this.props.items.length) {
			// eslint-disable-next-line react/no-did-update-set-state
			this.setState({
				items: this.props.items,
			});
		}
	}

	onDragEnd = result => {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		const items = reorder(
			this.state.items,
			result.source.index,
			result.destination.index,
		);

		this.setState({
			items,
		});

		const { dispatch, mediaCollectionID, type } = this.props;
		dispatch(callApi(updateMediaOrder.id, {
			id: mediaCollectionID,
			order: items.map(i => i.id),
			type,
		}));
	};

	onAddClick = () => {
		this.setState({ modalOpen: true });
	};

	onRemoveMedia = mediaID => {
		const { dispatch, mediaCollectionID } = this.props;

		// eslint-disable-next-line no-alert
		if (window.confirm('Do you really want to remove this media?')) {
			dispatch(callApi(deleteMedia.id, {
				id: mediaID,
				media_collection_id: mediaCollectionID,
			}));
		}
	};

	onModalClose = () => {
		this.setState({ modalOpen: false });
	};

	// eslint-disable-next-line react/no-unused-class-component-methods
	DropZone = () => {
		const { dispatch, mediaCollectionID, type } = this.props;

		const onDrop = useCallback(acceptedFiles => {
			dispatch(callApi(uploadMedia.id, {
				id: mediaCollectionID,
				files: acceptedFiles,
				type,
			}));
		}, []);

		let accept = ['image/*'];
		switch (this.props.type) {
		case MediaVideoThumbnail:
			accept = ['video/mp4'];
			break;
		case MediaMisc:
			accept = undefined;
			break;
		}

		const {
			acceptedFiles,
			getRootProps,
			getInputProps,
			isFocused,
			isDragAccept,
			isDragReject,
		} = useDropzone({ accept, onDrop });

		const style = useMemo(() => ({
			...baseStyle,
			...(isFocused ? focusedStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
		}), [
			isFocused,
			isDragAccept,
			isDragReject,
		]);

		const files = acceptedFiles.map(file => (
			<li key={file.path}>
				{file.path}
				{' '}
				-
				{Math.round(file.size * 0.0001) / 100}
				{' '}
				MB -
				{this.props?.uploadStatus?.[file.path]}
			</li>
		));

		// disabled if there is one file uploading
		const disabled = typeof Object.values(this.props?.uploadStatus).find(status => status !== MediaStatusComplete) !== 'undefined';

		return (
			<section className="container">
				<div {...getRootProps({ style })}>
					<input {...getInputProps()} />
					<p>Drag&apos;n drop some files here, or click to select files</p>
				</div>
				<aside>
					<h4>Files</h4>
					<ul>{files}</ul>
				</aside>
				<Button disabled={disabled} submit size="normal" className={css.rightBottom} onClick={this.onModalClose}>{disabled ? 'Uploading...' : 'Done'}</Button>
			</section>
		);
	};

	// Normally you would want to split things out into separate components.
	// But in this example everything is just done in one place for simplicity
	render() {
		const { items, modalOpen } = this.state;
		const { type } = this.props;

		let title = '';
		switch (type) {
		case MediaImageThumbnail:
			title = 'Thumbnails';
			break;
		case MediaVideoThumbnail:
			title = 'Video Thumbnail';
			break;
		case MediaAlternativeThumbnail:
			title = 'AB Thumbnail';
			break;
		case MediaLandscapeScreenshot:
			title = 'Landscape Screenshot';
			break;
		case MediaPortraitScreenshot:
			title = 'Portrait Screenshot';
			break;
		case MediaMisc:
			title = 'Miscellaneous';
			break;
		}

		return (
			<>
				<ReactModal
					className={css.modal}
					overlayClassName={css.overlay}
					isOpen={modalOpen}
					onRequestClose={this.onModalClose}
					shouldCloseOnOverlayClick
				>
					<h2>
						Add
						{title}
					</h2>
					Note: Anything you upload is permanently public!
					<br />
					Note: Transcoding videos is a slow process, it might take ~10 min before your videos show up!
					<this.DropZone />
				</ReactModal>
				<DragDropContext onDragEnd={this.onDragEnd}>
					<Droppable droppableId="droppable">
						{(provided, snapshot) => (
							<div
								{...provided.droppableProps}
								ref={provided.innerRef}
								style={getListStyle(snapshot.isDraggingOver)}
							>
								<h4 className={css.title}>{title}</h4>
								<Button submit size="normal" className={css.addButton} onClick={this.onAddClick}>Add</Button>

								{items.map((item, index) => (
									<Draggable key={item.id} draggableId={`${item.id}`} index={index}>
										{(provided, snapshot) => (
											<div
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
												style={getItemStyle(
													snapshot.isDragging,
													provided.draggableProps.style,
												)}
											>
												<div className={css.close} onClick={() => this.onRemoveMedia(item.id)}>✖️</div>
												<div className={css.unit}>
													{(type === MediaImageThumbnail || type === MediaAlternativeThumbnail) && (<img className={css.unitSmall} src={item.CDNLocation} alt="" />)}
													{type === MediaVideoThumbnail && (<video autoPlay muted loop className={css.unitSmall} src={`${item.CDNLocation}.3x3.h264.mp4`} alt="" />)}
													{(type === MediaLandscapeScreenshot || type === MediaPortraitScreenshot) && (<img className={css.unitSmall} src={item.CDNLocation} alt="" />)}
													{type === MediaMisc && ('🗄️')}
													<div className={css.unitText}>
														<span className={css.textSmall}>
															{index === 0 && (type !== MediaMisc) ? 'Active on Playground' : <a href={item.CDNLocation}>{item.source}</a>}
														</span>
													</div>
												</div>
											</div>
										)}
									</Draggable>
								))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</>
		);
	}
}

export default connect((state, { mediaCollectionID }) => ({
	uploadStatus: selectMediaUploadStatus(state, mediaCollectionID),
	user: selectUser(state),
}))(MediaDragDropList);
