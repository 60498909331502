import { apiSuccess } from 'app/actions/api';

import { getMedias, uploadMedia } from 'app/epics/medias';
import { setUploadedMedia, setTranscodedMedia, setTranscodingMedia } from 'app/actions/medias';
import { CDNLocation, MediaStatusComplete, MediaStatusUploading, MediaStatusTranscoding } from 'app/components/editors/util/mediaTypes';

export const initialState = {
	data: {},
	uploaded: {},
	success: false,
	error: undefined,
	pending: true,
};

export default function mediaReducer(state = initialState, action) {
	if (action.type === setUploadedMedia.type || action.type === setTranscodedMedia.type) {
		return {
			...state,
			uploaded: {
				...state.uploaded,
				[action.id]: {
					...state.uploaded[action.id],
					[action.path]: MediaStatusComplete,
				},
			},
		};
	}

	if (action.type === setTranscodingMedia.type) {
		return {
			...state,
			uploaded: {
				...state.uploaded,
				[action.id]: {
					...state.uploaded[action.id],
					[action.path]: MediaStatusTranscoding,
				},
			},
		};
	}

	// Set media upload status
	if (action.id === uploadMedia.id && action.type !== apiSuccess.type) {
		const status = [];
		action.options.files.forEach(f => {
			status[f.path] = MediaStatusUploading;
		});

		return {
			...state,
			uploaded: {
				...state.uploaded,
				[action.options.id]: {
					...state.uploaded[action.options.id],
					...status,
				},
			},
		};
	}

	if (action.type === apiSuccess.type) {
		if (action.id === getMedias.id) {
			return {
				...state,
				data: {
					...state.data,
					[action.options.id]: [
						...action.response.medias.map(media => ({
							...media,
							CDNLocation: CDNLocation(media),
						})),
					],
				},
			};
		}
	}

	return state;
}
