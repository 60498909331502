import PT from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Fuse from 'fuse.js';

import { callApi } from 'app/actions/api';
import Title from 'app/components/ui/Title';
import Button from 'app/components/ui/Button';
import { selectAPIStatus } from 'app/selectors/api';
import { getAllCreatives } from 'app/epics/creatives';
import * as types from 'app/types';
import { selectCreatives } from 'app/selectors/creatives';
import { Link } from 'react-router-dom';
import DataTable from 'app/components/ui/DataTable';

export class CreativesOverview extends React.PureComponent {
	static propTypes = {
		dispatch: PT.func.isRequired,
		creatives: PT.arrayOf(types.creative).isRequired,
		getCreativeApiStatus: types.apiStatus.isRequired,
	};

	static columns = [
		{
			name: 'Active',
			cell: row => {
				let cellData = '🟢';
				if (Date.now() > new Date(row.end_date).getTime()) {
					cellData = '🔴';
				}
				return (<div>{ cellData }</div>);
			},
			sortable: true,
			width: '30px',
		},
		{
			selector: row => row.name,
			name: 'Name',
			sortable: true,
		},
		{
			selector: row => row.type,
			name: 'Type',
			sortable: true,
		},
		{
			selector: row => row.click_through_url,
			name: 'Click-through URL',
			sortable: true,
		},
		{
			selector: row => row.created_by,
			name: 'Created By',
			sortable: true,
		},
		{
			selector: row => row.created_at,
			name: 'Created At',
			sortable: true,
		},
		{
			name: 'Edit',
			cell: row => <Link to={`/ads/creatives/${row.id}/edit`}><input type="button" value="Edit" /></Link>,
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		},
	];

	componentDidMount() {
		const { dispatch } = this.props;
		dispatch(callApi(getAllCreatives.id));
	}

	render() {
		const { getCreativeApiStatus, creatives } = this.props;

		// Move the in-active ones to the back
		creatives.sort(a => (Date.now() > new Date(a.end_date).getTime() ? 1 : 0));

		const searchIndex = new Fuse(creatives, {
			caseSensitive: false,
			shouldSort: true,
			threshold: 0.2,
			distance: 100,
			findAllMatches: true,
			useExtendedSearch: true,
			keys: [
				'name',
				'type',
				'created_by',
				'click_through_url',
			],
		});

		return (
			<div>
				<Title>Ad Creatives</Title>
				<Button to="/ads/creatives/add">Add Creative</Button>
				<Button to="/ads/creatives/bulkhouseads">Bulk housead Upload</Button>

				<DataTable
					columns={CreativesOverview.columns}
					data={creatives}
					searchIndex={searchIndex}
					apiStatus={getCreativeApiStatus}
				/>
			</div>
		);
	}
}

export default connect(state => ({
	getCreativeApiStatus: selectAPIStatus(state, getAllCreatives.id),
	creatives: selectCreatives(state),
}))(CreativesOverview);
