import { apiEpic } from 'app/utils/api';

export const getAdsTxt = apiEpic(
	'adstxt/get',
	(api, siteID) => api(`/secure/adstxt?site=${siteID}`, { responseType: 'text' }),
);

export const updateAdsTxt = apiEpic(
	'adstxt/update',
	(api, { siteID, data }) => api(`/secure/adstxt?site=${siteID}`, {
		body: data,
		method: 'POST',
		headers: {
			'Content-Type': 'text/plain; charset=utf-8',
		},
	}),
);
