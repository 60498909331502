export function categoryDetailsSchema() {
	return {
		// Our form
		type: 'object',
		title: 'Category Editor',
		properties: {
			iab_categories: {
				type: 'string',
				title: 'IAB Categories',
			},
			emoji: {
				type: 'string',
				title: 'Emoji',
			},
		},
	};
}

export const uiCategoryDetailsSchema = {};
