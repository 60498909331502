import api from 'app/utils/api';
import languageSelector from 'app/utils/languageSelector';

const postData = async (url = '', data = {}) => {
	const { response } = await api(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data),
	}).toPromise();

	return response;
};

function fixENTextFromTranslation(content) {
	content = content.replaceAll(/<(.*?)> /g, '<$1>'); // Remove space after tag that Google Translate always adds.
	content = content.replaceAll('<linebreak />', '\n');
	content = content.replaceAll('<prespace />', ' ');
	content = content.replaceAll('<postspace />', ' ');
	content = content.replaceAll('ᴘᴏᴋɪ', 'Poki');
	return content;
}

function prepareENTextForTranslation(content) {
	content = content.replaceAll('\n', '<linebreak />');
	content = content.replaceAll(/\s<(.*?)>/g, '<prespace /><$1>');
	content = content.replaceAll(/<(.*?)>\s/g, '<$1><postspace />');
	content = content.replaceAll('Poki', 'ᴘᴏᴋɪ'); // fixes a bug where Poki is translated to Pok in french
	return content;
}

let spinnerOverlay;
function createSpinner() {
	spinnerOverlay = document.createElement('div');
	spinnerOverlay.classList.add('lds');
	spinnerOverlay.style.position = 'fixed';
	spinnerOverlay.style.top = '0';
	spinnerOverlay.style.left = '0';
	spinnerOverlay.style.width = '100%';
	spinnerOverlay.style.height = '100%';
	spinnerOverlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
	spinnerOverlay.style.zIndex = '9999';

	const spinner = document.createElement('div');
	spinner.innerHTML = '<div></div><div></div><div></div><div></div>';
	spinner.classList.add('lds-ring');
	spinnerOverlay.appendChild(spinner);

	const span = document.createElement('span');
	span.innerHTML = 'Depending on the amount of text, this may take a while...';
	span.classList.add('lds-text');
	spinnerOverlay.appendChild(span);

	// create three quarter spinner
	const spinnerAnimationStyle = `
		.lds {
			color: #ffffff;
			font-size: 16px;
		}
		.lds-text {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			margin-top: 100px;
		}
		.lds-ring {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
		.lds-ring,
		.lds-ring div {
			box-sizing: border-box;
		}
		.lds-ring {
			display: inline-block;
			position: relative;
			width: 80px;
			height: 80px;
		}
		.lds-ring div {
			box-sizing: border-box;
			display: block;
			position: absolute;
			width: 64px;
			height: 64px;
			margin: 8px;
			border: 8px solid currentColor;
			border-radius: 50%;
			animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
			border-color: currentColor transparent transparent transparent;
		}
		.lds-ring div:nth-child(1) {
			animation-delay: -0.45s;
		}
		.lds-ring div:nth-child(2) {
			animation-delay: -0.3s;
		}
		.lds-ring div:nth-child(3) {
			animation-delay: -0.15s;
		}
		@keyframes lds-ring {
			0% {
				transform: rotate(0deg);
			}
			100% {
				transform: rotate(360deg);
			}
		}
	`;

	const style = document.createElement('style');
	style.innerHTML = spinnerAnimationStyle;
	document.head.appendChild(style);
}

function showSpinner(bool) {
	if (!spinnerOverlay) {
		createSpinner();
	}

	if (bool) {
		document.body.appendChild(spinnerOverlay);
	} else {
		document.body.removeChild(spinnerOverlay);
	}
}

export async function autoTranslateForm() {
	const { formShadowData } = this.state;
	let keys = [];

	// fetch keys from current schema
	if (this.constructor.steps[this.state.step].schema().properties) {
		keys = Object.keys(this.constructor.steps[this.state.step].schema().properties);
	}

	let languages = [];
	let translateKeys = [];
	let service = '';
	let general = '';
	let languageSpecific = '';
	try {
		const [selectedLanguages, selectedKeys, selectedService, generalPrompt, languageSpecificPrompt] = await languageSelector(keys);
		languages = selectedLanguages;
		translateKeys = selectedKeys;
		service = selectedService;
		general = generalPrompt;
		languageSpecific = languageSpecificPrompt;
	} catch (err) { /* empty */ }

	if (!languages?.length || (keys.length && !translateKeys?.length)) {
		return;
	}

	showSpinner(true);

	const translationPromises = [];

	let englishSourceMissing = false;

	let enText = '';
	if (keys.length) {
		translateKeys.forEach(key => {
			enText = formShadowData[key].find(t => t.lang === 'en')?.content;

			if (enText) {
				translationPromises.push(postData('/secure/translate', {
					text: prepareENTextForTranslation(enText),
					languages,
					service,
					general_prompt: general,
					language_specific_prompt: languageSpecific,
				}));
			} else {
				englishSourceMissing = true;
			}
		});
	} else {
		enText = formShadowData.find(t => t.lang === 'en')?.content;

		if (enText) {
			translationPromises.push(postData('/secure/translate', {
				text: prepareENTextForTranslation(enText),
				languages,
				service,
				general_prompt: general,
				language_specific_prompt: languageSpecific,
			}));
		} else {
			englishSourceMissing = true;
		}
	}

	// if any key does not have english text, we can't translate, alert and return
	if (englishSourceMissing) {
		alert('English text is needed for translation'); // eslint-disable-line no-alert
		return;
	}

	Promise.all(translationPromises).then(responses => {
		let translatedData = {
			...formShadowData,
		};

		responses.forEach((response, index) => {
			let currentFormData;

			if (keys.length) {
				const key = translateKeys[index];
				currentFormData = formShadowData[key];
			} else {
				currentFormData = formShadowData;
			}

			languages.forEach(lang => {
				if (response[lang] !== undefined) {
					let langIndex = currentFormData.findIndex(t => t.lang === lang);
					if (langIndex < 0) {
						langIndex = currentFormData.length;
					}
					currentFormData[langIndex] = {
						lang,
						content: fixENTextFromTranslation(response[lang]),
					};
				}
			});

			if (keys.length) {
				const key = translateKeys[index];
				translatedData[key] = currentFormData;
			} else {
				translatedData = currentFormData;
			}
		});

		this.setState({ formData: JSON.parse(JSON.stringify(translatedData)) });

		showSpinner(false);
	}).catch(err => console.info('Auto translate error:', err));
}
