import { pageview } from 'app/actions/page';

export const initialState = {
	pageNum: 0,
};

export default function pageReducer(state = initialState, action) {
	if (action.type === pageview.type) {
		return {
			...state,
			pageNum: state.pageNum + 1,
		};
	}

	return state;
}
