import { routerMiddleware } from 'react-router-redux';
import { applyMiddleware, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';

import epic from 'app/epics';
import reducer from 'app/reducers';
import api from 'app/utils/api';

export default function configureStore(history, initialState) {
	const epicMiddleware = createEpicMiddleware({
		dependencies: { api },
	});

	const middleware = applyMiddleware(
		routerMiddleware(history),
		epicMiddleware,
	);

	const store = createStore(reducer, initialState, middleware);

	epicMiddleware.run(epic);

	return store;
}
