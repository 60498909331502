import PT from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { callApi } from 'app/actions/api';
import { createGame, updateGame } from 'app/epics/games';
import GameForm from 'app/components/forms/GameForm';
import { selectAPIStatus } from 'app/selectors/api';
import css from 'app/styles/form.css';
import * as types from 'app/types';
import { getTopLevelCategories } from 'app/epics/categories';
import { combinedApiStatus } from 'app/utils/api';
import { getSites } from 'app/epics/sites';
import { getSeries } from 'app/epics/series';
import { selectTopLevelCategories } from 'app/selectors/categories';
import { selectSites } from 'app/selectors/sites';
import { selectSeries } from 'app/selectors/series';

export class GameEditor extends React.PureComponent {
	static propTypes = {
		categories: PT.arrayOf(types.game).isRequired,
		sites: PT.objectOf(types.site).isRequired,
		series: PT.arrayOf(types.series).isRequired,
		dispatch: PT.func.isRequired,
		game: types.game,
		createAPIStatus: types.apiStatus.isRequired,
		updateAPIStatus: types.apiStatus.isRequired,
	};

	static defaultProps = {
		game: null,
	};

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(callApi(getTopLevelCategories.id));
		dispatch(callApi(getSites.id));
		dispatch(callApi(getSeries.id));
	}

	handleSubmitGame = data => {
		const { dispatch } = this.props;

		if (typeof data.id === 'undefined') {
			dispatch(callApi(createGame.id, data));
		} else {
			dispatch(callApi(updateGame.id, data));
		}
	};

	render() {
		const { categories, sites, series, game, createAPIStatus, updateAPIStatus } = this.props;

		const apiStatus = combinedApiStatus(updateAPIStatus, createAPIStatus);

		return (
			<div>
				{apiStatus.error && (
					<p className={css.error}>
						<strong>{apiStatus.error}</strong>
					</p>
				)}
				{apiStatus.pending && (
					<div>Submitting...</div>
				)}
				{createAPIStatus.success
					&& (
						<div>
							Good news! Your new game was stored in the database!
							<Link to="/games">Back to Games</Link>
						</div>
					)}
				{updateAPIStatus.success
					&& (
						<div>
							Good news! Your update was stored in the database!
							<Link to="/games">Back to Games</Link>
						</div>
					)}
				{!createAPIStatus.success && !updateAPIStatus.success
					&& (
						<GameForm
							details={game ? game.details : undefined}
							categories={categories}
							sites={sites}
							series={series}
							onSubmit={this.handleSubmitGame}
						/>
					)}
			</div>
		);
	}
}

export default connect(state => ({
	categories: selectTopLevelCategories(state),
	sites: selectSites(state),
	series: selectSeries(state),
	createAPIStatus: selectAPIStatus(state, createGame.id),
	updateAPIStatus: selectAPIStatus(state, updateGame.id),
}))(GameEditor);
