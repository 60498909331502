import React from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';

import { callApi } from 'app/actions/api';
import { getGame } from 'app/epics/games';
import { selectGame } from 'app/selectors/games';
import { selectAPIStatus } from 'app/selectors/api';
import Title from 'app/components/ui/Title';
import GameEditor from 'app/components/editors/GameEditor';

import * as types from 'app/types';
import GameInfo from 'app/components/ui/GameInfo';

class GameEdit extends React.PureComponent {
	static propTypes = {
		dispatch: PT.func.isRequired,
		game: types.game,
		match: PT.shape({
			params: PT.shape({
				id: PT.string.isRequired,
			}).isRequired,
		}).isRequired,
		getGameAPIStatus: types.apiStatus.isRequired,
	};

	static defaultProps = {
		game: null,
	};

	componentDidMount() {
		const { dispatch, match } = this.props;

		const id = parseInt(match.params.id, 10);
		dispatch(callApi(getGame.id, { id }));
	}

	render() {
		const { getGameAPIStatus, game, match } = this.props;

		const englishTitle = game?.details?.titles ? game.details.titles.find(t => t.lang === 'en').content : 'No title';
		const image = game && game.details ? game.details.image : undefined;

		const { id } = match.params;

		return (
			<>
				<GameInfo id={id} image={image} title={englishTitle} categories={game?.details?.categories || []} />
				<Title>Edit Game</Title>
				{getGameAPIStatus.pending ? (
					<div>Loading...</div>
				) : getGameAPIStatus.error ? (
					<div>Error</div>
				) : !game ? (
					<div>Game not found</div>
				) : (
					<GameEditor
						game={game}
					/>
				)}
			</>
		);
	}
}

export default connect((state, { match }) => ({
	game: selectGame(state, match.params.id),
	getGameAPIStatus: selectAPIStatus(state, getGame.id),
}))(GameEdit);
