import { ajax } from 'rxjs/ajax';
import xhr2 from 'xhr2';
import { of, empty, throwError } from 'rxjs';
import { ofType } from 'redux-observable';
import { filter, mergeMap, switchMap, catchError, map } from 'rxjs/operators';

import { apiFetch, apiSuccess, apiError } from 'app/actions/api';

export const SERVICE_ENV = typeof window === 'undefined'
	? process.env.SERVICE_ENV
	: window.ENV.SERVICE_ENV;

const apiUrls = {
	acceptance: 'https://api-acceptance.poki.io',
	production: 'https://api.poki.io',
	local: 'http://localhost:10019',
};

const apiUrl = SERVICE_ENV ? apiUrls[SERVICE_ENV] : apiUrls.acceptance;

const XHR2 = typeof XMLHttpRequest !== 'undefined'
	? XMLHttpRequest
	: xhr2;

export default function api(endpoint, options = {}) {
	const doRequest = opts => ajax({
		createXHR: () => new XHR2(),
		url: endpoint.startsWith('http') ? endpoint : `${apiUrl}${endpoint}`,
		...opts,
		crossDomain: true,
	});

	const refreshToken = window.localStorage.getItem('refresh_token');
	if (refreshToken) {
		const accessToken = window.localStorage.getItem('access_token');
		const expires = window.localStorage.getItem('expires');

		let stage = SERVICE_ENV || 'acceptance';
		if (stage === 'local') {
			stage = 'acceptance';
		}

		if (expires < Date.now()) {
			let refreshURL = `https://auth-${stage}.poki.io/auth/refresh`;
			if (stage === 'production') {
				refreshURL = 'https://auth.poki.io/auth/refresh';
			}

			return ajax({
				url: refreshURL,
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ refresh_token: refreshToken }),
			}).pipe(
				mergeMap(({ response }) => {
					if (response.access_token) {
						window.localStorage.setItem('access_token', response.access_token);
					}
					if (response.ttl) {
						window.localStorage.setItem('expires', Date.now() + (response.ttl * 1000));
					}

					return doRequest({
						...options,
						headers: {
							...options.headers,
							Authorization: `Bearer ${response.access_token}`,
						},
					});
				}),
				catchError(err => {
					// Only catch errors from the refresh url.
					// Let errors caused by the actual API call pass through.
					if (err.request.url === refreshURL) {
						console.error(`failed to refresh tokens: ${err}`);
						if (err.status >= 401 && err.status <= 499) {
							window.localStorage.removeItem('refresh_token');
							window.location = '/login/expired';
						}
						return empty();
					}

					return throwError(err);
				}),
			);
		}

		return doRequest({
			...options,
			headers: {
				...options.headers,
				Authorization: `Bearer ${accessToken}`,
			},
		});
	}

	return doRequest(options);
}

export const apiEpic = (id, handler, parallel = true) => {
	const epic = (action$, _, { api: _api }) => action$.pipe(
		ofType(apiFetch.type),
		filter(({ id: actionId }) => id === actionId),
		(parallel ? mergeMap : switchMap)(action => (
			handler(_api, action.options).pipe(
				map(({ response }) => apiSuccess({ id, options: action.options, response })),
				catchError(err => {
					let error = err.message;
					if (err.response && err.response.error) {
						error = err.response.error; // eslint-disable-line prefer-destructuring
					}
					return of(apiError({ id, options: action.options, error }));
				}),
			)
		)),
	);
	epic.id = id;
	return epic;
};

export const combinedApiStatus = (...statusses) => {
	const result = {
		success: true,
		pending: false,
		error: null,
	};

	statusses.forEach(status => {
		result.success = result.success && status.success; // All statusses need to have success
		result.pending = result.pending || status.pending; // Any status needs to be pending
		result.error = result.error || status.error; // Any status needs to have an error
	});

	return result;
};
