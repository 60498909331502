import PT from 'prop-types';
import React from 'react';
import DataTable from 'react-data-table-component';

import * as types from 'app/types';

function FilterComponent({ filterText, onFilter }) {
	return (
		<input
			id="search"
			type="text"
			placeholder="Filter By Name"
			aria-label="Search Input"
			value={filterText}
			onChange={onFilter}
		/>
	);
}

FilterComponent.propTypes = {
	filterText: PT.string.isRequired,
	onFilter: PT.func.isRequired,
};

export default function Table({ apiStatus, data, columns, searchIndex, paginationPerPage, defaultSortFieldId, defaultSortAsc }) {
	const [filterText, setFilterText] = React.useState('');
	const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

	const filteredItems = !filterText ? data : searchIndex.search(filterText);

	const subHeaderComponentMemo = React.useMemo(() => {
		const handleClear = () => {
			if (filterText) {
				setResetPaginationToggle(!resetPaginationToggle);
				setFilterText('');
			}
		};

		return (
			<FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
		);
	}, [filterText, resetPaginationToggle]);

	if (apiStatus.error) {
		return (<div>Error</div>);
	} if (data.length === 0 && !apiStatus.pending) {
		return (<div>No data?! Hang on.</div>);
	} if (data.length === 0 && apiStatus.pending) {
		return (<div>Loading...</div>);
	}

	return (
		<DataTable
			columns={columns}
			data={filteredItems}
			pagination
			paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
			subHeader
			subHeaderComponent={subHeaderComponentMemo}
			persistTableHead
			highlightOnHover
			responsive
			paginationPerPage={paginationPerPage}
			defaultSortFieldId={defaultSortFieldId}
			defaultSortAsc={defaultSortAsc}
		/>
	);
}

Table.propTypes = {
	columns: PT.arrayOf(PT.shape({
		dataKey: PT.string.isRequired,
		label: PT.string.isRequired,
		width: PT.number.isRequired,
	})).isRequired,
	apiStatus: types.apiStatus.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	data: PT.arrayOf(PT.object).isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	searchIndex: PT.object.isRequired,
	paginationPerPage: PT.number,
	defaultSortFieldId: PT.string,
	defaultSortAsc: PT.bool,
};

Table.defaultProps = {
	paginationPerPage: 30,
	defaultSortFieldId: undefined,
	defaultSortAsc: undefined,
};
