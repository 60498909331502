import { apiEpic } from 'app/utils/api';

export const searchRedirects = apiEpic(
	'redirects/search',
	(api, { sourceURL, targetURL }) => api(`/secure/redirects/search?source_url=${sourceURL}&target_url=${targetURL}`),
	false, // Don't do multiple requests in parallel, cancel all previous requests.
);

export const deleteRedirects = apiEpic(
	'redirects/delete',
	(api, data) => api('/secure/redirects', {
		body: data,
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
		},
	}),
);

export const addRedirects = apiEpic(
	'redirects/add',
	(api, data) => api('/secure/redirects', {
		body: data,
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
	}),
);
