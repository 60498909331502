import PT from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import css from 'app/styles/ui/redirects.css';
import Title from 'app/components/ui/Title';
import Button from 'app/components/ui/Button';
import * as types from 'app/types';
import { callApi } from 'app/actions/api';
import { addRedirects } from 'app/epics/redirects';
import { selectAPIStatus } from 'app/selectors/api';

const prepareRedirects = rawRedirects => new Promise((resolve, reject) => {
	const result = [];
	const lines = rawRedirects.split('\n');

	for (let i = 0; i < lines.length; i++) {
		const [siteIDStr, countryID, sourceURL, targetURL] = lines[i]
			.trim()
			.split(',');

		const errorMessage = `Error on line ${i + 1}: siteID: ${siteIDStr}, countryID: ${countryID}, sourceURL: ${sourceURL}, targetURL: ${targetURL}`;

		if (!siteIDStr) {
			reject(new Error(`Missing site ID. ${errorMessage}`));
		}
		const siteID = parseInt(siteIDStr, 10);

		if (!sourceURL || !targetURL) {
			reject(new Error(`Missing Source or Target URL. ${errorMessage}`));
		}

		if (countryID && countryID.length > 2) {
			reject(new Error(`Country ID must be 2 characters. ${errorMessage}`));
		}

		result.push({
			site_id: siteID,
			country_id: countryID,
			source_url: sourceURL,
			target_url: targetURL,
		});
	}

	resolve(result);
});

export class RedirectsAdd extends React.PureComponent {
	static propTypes = {
		dispatch: PT.func.isRequired,
		addRedirectsStatus: types.apiStatus.isRequired,
	};

	constructor(props) {
		super(props);
		this.textArea = React.createRef();
		this.state = {
			edited: false,
		};
	}

	handleSave = () => {
		const { dispatch } = this.props;

		prepareRedirects(this.textArea.current.value)
			.then(redirects => {
				dispatch(callApi(addRedirects.id, redirects));
			})
			.catch(e => {
				// eslint-disable-next-line no-alert
				alert(e);
			});
	};

	render() {
		const { addRedirectsStatus } = this.props;
		const { edited } = this.state;
		const { success, pending } = addRedirectsStatus;

		let buttonText = 'Add';
		if (pending) buttonText = 'Adding...';
		if (success && !edited) buttonText = 'Added!';

		return (
			<>
				<Title>Add redirects</Title>
				<p>Here you can upload a batch of redirects in CSV format.</p>
				<textarea
					className={css.textarea}
					onChange={this.textChanged}
					ref={this.textArea}
					readOnly={pending}
					placeholder="e.g. `12,,/g/from,/g/to` where `12` is a site id, `/g/from` is a source URL, `/g/to` is a target URL&#10;or `3,NL,/g/from,/g/to` where `NL` is a geo, etc..."
				/>
				<Button primary submit onClick={this.handleSave}>
					{buttonText}
				</Button>
			</>
		);
	}
}
export default connect(state => ({
	addRedirectsStatus: selectAPIStatus(state, addRedirects.id),
}))(RedirectsAdd);
