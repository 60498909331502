import { createSelector } from 'reselect';

export const selectLocalisations = createSelector(
	state => state.localisations,
	localisations => localisations.data,
);

export const selectLocalisation = createSelector(
	state => state.localisations,
	localisations => localisations.current,
);
