import React from 'react';
import PT from 'prop-types';
import Form from 'react-jsonschema-form';

import { bulkHouseAdConfig, uiBulkHouseAdConfig } from 'app/forms/bulk-housead';
import gameSelectorWidget from 'app/components/forms/widgets/GameSelectorWidget';

export default class extends React.Component {
	static propTypes = {
		onChange: PT.func.isRequired,
		onSubmit: PT.func.isRequired,
	};

	shouldComponentUpdate() { return false; }

	render() {
		const { onChange, onSubmit } = this.props;

		return (
			<div>
				<Form
					schema={bulkHouseAdConfig()}
					uiSchema={uiBulkHouseAdConfig}
					onChange={onChange}
					noValidate
					onSubmit={onSubmit}
					widgets={{
						gameSelectorWidget,
					}}
				>
					<div />
				</Form>
			</div>
		);
	}
}
