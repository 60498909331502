import PT from 'prop-types';

export const creative = PT.shape({
	id: PT.string,
	name: PT.string,
	status: PT.string,
	game_id: PT.number,
	width: PT.number,
	height: PT.number,
	type: PT.string,
	asset: PT.string,
	created_by: PT.string,
	updated_by: PT.string,
	created_at: PT.string,
	updated_at: PT.string,
});

export const image = PT.shape({
	path: PT.string.isRequired,
	x: PT.number,
	y: PT.number,
});

export const video = PT.shape({
	path: PT.string.isRequired,
});

export const media = PT.shape({
	type: PT.string.isRequired,
	source: PT.string.isRequired,
});

export const details = PT.shape({
	width: PT.number,
	height: PT.number,
});

export const game = PT.shape({
	details,
	image,
	videos: PT.objectOf(video),
});

export const categorySummary = PT.shape({
	id: PT.string.isRequired,
	mobile: PT.bool.isRequired,
	slug: PT.string.isRequired,
	image: image.isRequired,
});

export const apiStatus = PT.shape({
	error: PT.string,
	success: PT.bool.isRequired,
	pending: PT.bool.isRequired,
});

export const category = PT.shape({
	id: PT.number.isRequired,
});

export const user = PT.shape({
	name: PT.string.isRequired,
	email: PT.string.isRequired,
	picture: PT.string.isRequired,
});

export const chartData = PT.array;

export const color = PT.string;

export const match = PT.shape({
	isExact: PT.bool,
	params: PT.shape({}).isRequired,
	path: PT.string.isRequired,
	url: PT.string.isRequired,
});

export const site = PT.shape({
	domain: PT.string.isRequired,
});

export const series = PT.shape({
	id: PT.number.isRequired,
	title: PT.string.isRequired,
});

export const localisation = PT.objectOf(PT.string);

export const redirect = PT.shape({
	site_id: PT.number.isRequired,
	country_id: PT.string.isRequired,
	source_url: PT.string.isRequired,
	target_url: PT.string.isRequired,
});

export const adSettings = PT.shape({
	blocklist: PT.string,
	country_exclusion: PT.string,
	bidder_limitation: PT.string,
});
