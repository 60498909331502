import React from 'react';
import PT from 'prop-types';
import Form from 'react-jsonschema-form';

import { localisationSchema, uiLocalisationchema } from 'app/forms/localisations';
import * as types from 'app/types';
import css from 'app/styles/forms/form.css';
import Button from 'app/components/ui/Button';
import { autoTranslateForm } from 'app/utils/autoTranslateForm';
import deepEqual from 'fast-deep-equal';

export default class LocalisationForm extends React.Component {
	static propTypes = {
		localisation: types.localisation,
		onSubmit: PT.func.isRequired,
	};

	static defaultProps = {
		localisation: undefined,
	};

	static steps = {
		1: {
			schema: localisationSchema,
			ui: uiLocalisationchema,
			title: 'Localizations',
		},
		2: {
			schema: () => ({}),
			ui: {},
			title: 'Save localizations',
		},
	};

	constructor(props) {
		super(props);

		this.state = {
			step: 1,
		};
	}

	componentDidMount() {
		// not receiving a prop update, but it re mounts for some reason, so not using UNSAFE_componentWillReceiveProps
		const { localisation } = this.props;

		// we receive localisation in a different format than the form expects
		if (localisation && Object.keys(localisation).length > 0) {
			// populate the formData
			const mapToLangLocales = Object.keys(localisation).map(locale => ({
				lang: locale,
				content: localisation[locale],
			}));

			this.setState({
				formData: mapToLangLocales,
				formShadowData: JSON.parse(JSON.stringify(mapToLangLocales)),
			});
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		// Don't re-render if formShadowData changes, but re-render on any other change
		const stateWithoutShadowFormData = { ...this.state };
		delete stateWithoutShadowFormData.formShadowData;

		const eqB = { ...nextState };
		delete eqB.formShadowData;

		return !deepEqual(nextProps, this.props) || !deepEqual(eqB, stateWithoutShadowFormData);
	}

	handleAutoTranslate = autoTranslateForm.bind(this);

	onSubmit = data => {
		if (this.state.step < Object.keys(LocalisationForm.steps).length) {
			this.setState({
				step: this.state.step + 1,
				formData: {
					...this.state.formData,
					...data.formData,
				},
			}, () => {
				if (this.state.step === Object.keys(LocalisationForm.steps).length) {
					this.props.onSubmit(this.state.formData);
				}
			});
		}
	};

	handleBack = () => {
		this.setState({
			step: this.state.step - 1,
		});
	};

	render() {
		const { step, formData } = this.state;

		if (!formData) {
			return <div>Loading...</div>;
		}

		const showForm = step < Object.keys(LocalisationForm.steps).length;
		const schema = LocalisationForm.steps[step].schema();

		return (
			<div>
				<Button primary onClick={this.handleAutoTranslate}>Auto translate</Button>
				<Form
					schema={schema}
					formData={formData}
					noValidate
					uiSchema={LocalisationForm.steps[step].ui}
					onSubmit={this.onSubmit}
				>
					{!showForm && (
						<div>Processing your update!! Hang in there!</div>
					)}
					<div>
						{LocalisationForm.steps[step - 1] && (
							<Button primary={false} size="normal" onClick={this.handleBack}>
								Back:
								{LocalisationForm.steps[step - 1].title}
							</Button>
						)}
						<Button submit size="normal" className={css.right}>
							Next:
							{LocalisationForm.steps[step + 1] ? LocalisationForm.steps[step + 1].title : 'Save'}
						</Button>
					</div>
				</Form>
			</div>
		);
	}
}
