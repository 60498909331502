import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';

import { pageview } from 'app/actions/page';
import Root from 'app/components/Root';
import history from 'app/history';
import store from 'app/store';
import { setUser } from 'app/actions/session';

window.store = store;

const refreshToken = window.localStorage.getItem('refresh_token');
if (refreshToken) {
	const claims = JSON.parse(atob(refreshToken.split('.', 3)[1]));
	store.dispatch(setUser({ user: claims }));
}

history.listen(() => {
	store.dispatch(pageview());
});

render(
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<Root />
		</ConnectedRouter>
	</Provider>,
	document.getElementById('app-root'),
);
