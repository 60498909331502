import { arrayMove } from 'react-sortable-hoc';

import { apiSuccess } from 'app/actions/api';

import {
	addCategoryGameItem,
	removeCategoryGameItem,
} from 'app/actions/categories';
import { getCategoryGames, getCategory, getAllCategories, getAllConsoleCategories, getTopLevelCategories } from 'app/epics/categories';

export const initialState = {
	categories: {},
	category: {},
	top_level_categories: {},
	console_categories: {},
};

export default function categoriesReducer(state = initialState, action) {
	if (action.type === apiSuccess.type && action.id === getAllCategories.id) {
		return {
			...state,
			categories: {
				...state.categories,
				...action.response.reduce((acc, item) => {
					acc[item.id] = item;
					return acc;
				}, {}),
			},
		};
	}

	if (action.type === apiSuccess.type && action.id === getAllConsoleCategories.id) {
		return {
			...state,
			console_categories: {
				...state.console_categories,
				...action.response.reduce((acc, item) => {
					acc[item.id] = item;
					return acc;
				}, {}),
			},
		};
	}

	if (action.type === apiSuccess.type && action.id === getTopLevelCategories.id) {
		return {
			...state,
			top_level_categories: {
				...state.top_level_categories,
				...action.response.reduce((acc, item) => {
					acc[item.id] = item;
					return acc;
				}, {}),
			},
		};
	}

	if (action.type === apiSuccess.type && action.id === getCategory.id) {
		return {
			...state,
			category: {
				...state.category,
				[action.response.id]: action.response,
			},
		};
	}

	if (action.type === apiSuccess.type && action.id === getCategoryGames.id) {
		return {
			...state,
			categories: {
				...state.categories,
				[action.options.id]: {
					...state.categories[action.options.id],
					games: action.response.games.map(({ id }) => id),
				},
			},
		};
	}

	if (action.type === addCategoryGameItem.type) {
		const { games } = state.categories[action.id];
		const index = games.findIndex(id => id === action.item.id);

		let content;
		if (index > -1) {
			content = arrayMove(games, index, 0);
		} else {
			content = [action.item.id, ...games];
		}

		return {
			...state,
			categories: {
				...state.categories,
				[action.id]: {
					...state.categories[action.id],
					games: content,
				},
			},
		};
	}

	if (action.type === removeCategoryGameItem.type) {
		const { games } = state.categories[action.id];
		const content = [...games];
		content.splice(action.index, 1);

		return {
			...state,
			categories: {
				...state.categories,
				[action.id]: {
					...state.categories[action.id],
					games: content,
				},
			},
		};
	}

	return state;
}
