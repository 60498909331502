import PT from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Fuse from 'fuse.js';

import Title from 'app/components/ui/Title';
import Button from 'app/components/ui/Button';
import DataTable from 'app/components/ui/DataTable';
import { selectAPIStatus } from 'app/selectors/api';
import { selectConsoleCategories } from 'app/selectors/categories';
import { getAllConsoleCategories } from 'app/epics/categories';
import * as types from 'app/types';

export class CategoryOverview extends React.PureComponent {
	static propTypes = {
		categories: PT.arrayOf(types.category).isRequired,
		apiStatus: types.apiStatus.isRequired,
	};

	static columns = [
		{
			selector: row => row.id,
			name: 'ID',
			sortable: true,
			width: '100px',
		},
		{
			selector: row => row.title,
			name: 'Title',
			sortable: true,
			width: '300px',
		},
		{
			selector: row => row.edit,
			name: 'Edit Category',
			width: '200px',
			cell: row => (
				<Link to={`/categories/${row.id}/edit`}>🗂️</Link>
			),
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		},
		{
			selector: row => row.thumbnails,
			name: 'Edit Thumbnail',
			width: '75px',
			cell: row => (
				<Link to={`/categories/${row.id}/media`}>🖼️</Link>
			),
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		},
		{
			selector: row => row.games,
			name: 'Edit Games in Category',
			width: '75px',
			cell: row => (
				<Link to={`/category-game/${row.id}/edit`}>🎮</Link>
			),
			ignoreRowClick: true,
			allowOverflow: true,
			button: true,
		},
	];

	render() {
		const { apiStatus, categories } = this.props;

		const searchIndex = new Fuse(categories, {
			caseSensitive: false,
			shouldSort: true,
			threshold: 0.3,
			distance: 100,
			findAllMatches: true,
			useExtendedSearch: true,
			keys: [
				'title',
			],
		});

		return (
			<div>
				<Title>Categories</Title>
				<Button to="/categories/add">Add Category</Button>
				<DataTable
					columns={CategoryOverview.columns}
					data={categories}
					searchIndex={searchIndex}
					apiStatus={apiStatus}
				/>
			</div>
		);
	}
}

export default connect(state => ({
	categories: selectConsoleCategories(state),
	apiStatus: selectAPIStatus(state, getAllConsoleCategories.id),
}))(CategoryOverview);
