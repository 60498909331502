import { createSelector } from 'reselect';

export const selectSites = createSelector(
	state => state.sites,

	sites => {
		const filtered = {};

		Object.entries(sites).forEach(([id, site]) => {
			if (site.iso_lang === 'xx' && site.id !== 55) return;

			filtered[id] = site;
		});

		return filtered;
	},
);
