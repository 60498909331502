import PT from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import css from 'app/styles/form.css';
import { callApi } from 'app/actions/api';
import { selectAPIStatus } from 'app/selectors/api';
import LocalisationForm from 'app/components/forms/LocalisationForm';
import { updateLocalisation } from 'app/epics/localisations';
import * as types from 'app/types';

export class LocalisationEditor extends React.PureComponent {
	static propTypes = {
		localisation: types.localisation,
		id: PT.string,
		dispatch: PT.func.isRequired,
		updateAPIStatus: types.apiStatus.isRequired,
	};

	static defaultProps = {
		localisation: null,
		id: '',
	};

	handleSubmitTranslation = formData => {
		const { dispatch, id } = this.props;
		const data = {
			id,
			formData: Object.values(formData),
		};
		dispatch(callApi(updateLocalisation.id, data));
	};

	render() {
		const { localisation, updateAPIStatus } = this.props;

		return (
			<div>
				{updateAPIStatus.error && (
					<p className={css.error}>
						<strong>{updateAPIStatus.error}</strong>
					</p>
				)}
				{updateAPIStatus.pending && (
					<div>Submitting...</div>
				)}
				{updateAPIStatus.success
					&& (
						<div>
							Good news! Your update was stored in the database!
							<Link to="/localisations">Back to localizations</Link>
						</div>
					)}
				{!updateAPIStatus.success
					&& (
						<LocalisationForm
							localisation={localisation}
							onSubmit={this.handleSubmitTranslation}
						/>
					)}
			</div>
		);
	}
}

export default connect(state => ({
	updateAPIStatus: selectAPIStatus(state, updateLocalisation.id),
}))(LocalisationEditor);
