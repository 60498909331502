import PT from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { callApi } from 'app/actions/api';
import { createCategory, updateCategory } from 'app/epics/categories';
import CategoryForm from 'app/components/forms/CategoryForm';
import { selectAPIStatus } from 'app/selectors/api';
import css from 'app/styles/form.css';
import * as types from 'app/types';
import { combinedApiStatus } from 'app/utils/api';
import { selectCategories } from 'app/selectors/categories';
import { selectSites } from 'app/selectors/sites';

export class CategoryEditor extends React.PureComponent {
	static propTypes = {
		categories: PT.arrayOf(types.category).isRequired,
		sites: PT.objectOf(types.site).isRequired,
		dispatch: PT.func.isRequired,
		category: types.category,
		createAPIStatus: types.apiStatus.isRequired,
		updateAPIStatus: types.apiStatus.isRequired,
	};

	static defaultProps = {
		category: null,
	};

	handleSubmitCategory = data => {
		const { dispatch } = this.props;

		if (typeof data.id === 'undefined') {
			dispatch(callApi(createCategory.id, data));
		} else {
			dispatch(callApi(updateCategory.id, data));
		}
	};

	render() {
		const { categories, sites, category, createAPIStatus, updateAPIStatus } = this.props;

		const apiStatus = combinedApiStatus(updateAPIStatus, createAPIStatus);

		return (
			<div>
				{apiStatus.error && (
					<p className={css.error}>
						<strong>{apiStatus.error}</strong>
					</p>
				)}
				{apiStatus.pending && (
					<div>Submitting...</div>
				)}
				{createAPIStatus.success
					&& (
						<div>
							Good news! Your new category was stored in the database!
							<Link to="/categories">Back to categories</Link>
						</div>
					)}
				{updateAPIStatus.success
					&& (
						<div>
							Good news! Your update was stored in the database!
							<Link to="/categories">Back to categories</Link>
						</div>
					)}
				{!createAPIStatus.success && !updateAPIStatus.success
					&& (
						<CategoryForm
							details={category || {}}
							categories={categories}
							sites={sites}
							onSubmit={this.handleSubmitCategory}
						/>
					)}
			</div>
		);
	}
}

export default connect(state => ({
	categories: selectCategories(state),
	sites: selectSites(state),
	createAPIStatus: selectAPIStatus(state, createCategory.id),
	updateAPIStatus: selectAPIStatus(state, updateCategory.id),
}))(CategoryEditor);
