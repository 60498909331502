import { apiEpic } from 'app/utils/api';

/* categories */
export const getAllCategories = apiEpic(
	'categories/all',
	api => api('/secure/all/categories'),
);

export const getAllConsoleCategories = apiEpic(
	'categories/include_empty',
	api => api('/secure/all/categories/include_empty'),
);

export const getTopLevelCategories = apiEpic(
	'categories/top_level',
	api => api('/secure/all/categories/top'),
);

export const getCategory = apiEpic(
	'categories/get',
	(api, { id }) => api(`/secure/category/${id}`),
);

export const createCategory = apiEpic(
	'categories/create',
	(api, data) => api('/secure/category', {
		body: data,
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
	}),
);

export const updateCategory = apiEpic(
	'categories/update',
	(api, data) => api(`/secure/category/${data.id}`, {
		body: data,
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
		},
	}),
);

/* category-game */
export const getCategoryGames = apiEpic(
	'category-game/id',
	(api, { id }) => api(`/secure/category-game/${id}`),
);

export const saveCategoryGames = apiEpic(
	'category-game/save',
	(api, { id, games }) => api(`/secure/category-game/${id}`, {
		body: JSON.stringify({ games: games.map(d => d.id) }),
		method: 'PUT',
	}),
);
