import css from 'app/styles/ui/button.css';
import classNames from 'app/utils/classNames';

const languages = ['Arabic - ar', 'Bulgarian - bg', 'Bengali - bn', 'Czech - cs', 'Danish - da', 'German - de', 'Greek - el', 'English - en', 'Spanish - es', 'Finnish - fi', 'French - fr', 'Hebrew - he', 'Hindi - hi', 'Hungarian - hu', 'Indonesian - id', 'Italian - it', 'Japanese - ja', 'Korean - ko', 'Malay - ms', 'Dutch - nl', 'Norwegian - no', 'Polish - pl', 'Portuguese - pt', 'Romanian - ro', 'Russian - ru', 'Slovak - sk', 'Serbian - sr', 'Swedish - sv', 'Thai - th', 'Tagalog - tl', 'Turkish - tr', 'Ukrainian - uk', 'Uzbek - uz', 'Chinese - zh'];
const translationServices = ['Google', 'DeepL', 'ChatGPT'];
const defaultGeneralPrompt = 'I have the following text in English: <text>.';
const defaultLangSpecificPrompt = "Translate the text to <lang_name>. Use the <lang_script> script. Use and keep the html formatting (don't remove tags). Return nothing other than the translated string as I will use it in a production environment as you return it.";

export default async keys => {
	/* Create pseudo element for dark background */
	const pseudo = document.createElement('div');
	pseudo.style = `
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		z-index: 999;
		background: rgba(0, 0, 0, 0.5);
	`;
	document.body.appendChild(pseudo);

	/* Create translator element */

	const translator = document.createElement('div');
	translator.style = `
		position: absolute;
		overflow: auto;
		max-height: calc(100vh - 100px);
		z-index: 1000;
		min-width: 640px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background: white;
		padding: 12px;
		border: 1px solid rgb(192, 192, 192);
		border-radius: 8px;
	`;

	document.body.appendChild(translator);

	/* Create header */

	const header = document.createElement('h1');
	header.innerText = 'Languages';
	translator.appendChild(header);

	/* Create language checkboxes */

	const languageGrid = document.createElement('div');
	languageGrid.style = `
		display: grid;
		grid-template-columns: repeat(6, auto);
		gap: 4px;
	`;
	translator.appendChild(languageGrid);

	languages.forEach(language => {
		const checkbox = document.createElement('input');
		checkbox.type = 'checkbox';
		checkbox.name = language;
		checkbox.checked = true;

		const label = document.createElement('label');
		label.htmlFor = language;
		label.appendChild(document.createTextNode(language));

		languageGrid.appendChild(checkbox);
		languageGrid.appendChild(label);
	});

	/* Language selection buttons */

	const selectAll = document.createElement('button');
	selectAll.innerText = 'Select all';
	selectAll.onclick = () => {
		languages.forEach(language => {
			const checkbox = languageGrid.querySelector(`input[name='${language}']`);
			checkbox.checked = true;
		});
	};
	selectAll.classList.add(...classNames(css.button, {
		[css.primary]: true,
		[css.small]: true,
	}).split(' '));
	selectAll.style.float = 'right';
	selectAll.style.margin = '4px';
	translator.appendChild(selectAll);

	const selectNone = document.createElement('button');
	selectNone.innerText = 'Select none';
	selectNone.onclick = () => {
		languages.forEach(language => {
			const checkbox = languageGrid.querySelector(`input[name='${language}']`);
			checkbox.checked = false;
		});
	};
	selectNone.classList.add(...classNames(css.button, {
		[css.primary]: true,
		[css.small]: true,
	}).split(' '));
	selectNone.style.float = 'right';
	selectNone.style.margin = '4px';
	translator.appendChild(selectNone);

	/* Create multi key selector */
	translator.appendChild(document.createElement('br'));

	let keySelector;
	if (keys.length) {
		const keyTitle = document.createElement('h2');
		keyTitle.innerText = 'Keys';
		translator.appendChild(keyTitle);

		keySelector = document.createElement('div');
		keySelector.style = `
			display: flex;
			gap: 12px;
		`;
		translator.appendChild(keySelector);

		keys.forEach(key => {
			const checkbox = document.createElement('input');
			checkbox.type = 'checkbox';
			checkbox.name = key;
			checkbox.checked = false;

			const label = document.createElement('label');
			label.appendChild(checkbox);
			label.appendChild(document.createTextNode(key));

			keySelector.appendChild(label);
		});
	}

	/* Create translation service dropdown */
	const serviceTitle = document.createElement('h2');
	serviceTitle.innerText = 'Translation Service';
	serviceTitle.style.whiteSpace = 'nowrap';
	translator.appendChild(serviceTitle);

	const serviceDropdown = document.createElement('select');
	serviceDropdown.style = `
        width: 100%;
        padding: 8px;
        margin-bottom: 12px;
        border-radius: 4px;
        border: 1px solid rgb(192, 192, 192);
    `;
	translator.appendChild(serviceDropdown);

	translationServices.forEach(service => {
		const option = document.createElement('option');
		option.value = service.toLowerCase();
		option.text = service;
		serviceDropdown.appendChild(option);
	});

	/* Create ChatGPT prompt textareas */
	const promptsContainer = document.createElement('div');
	promptsContainer.style.display = 'none';
	translator.appendChild(promptsContainer);

	const generalPromptLabel = document.createElement('label');
	generalPromptLabel.innerText = 'General Prompt:';
	generalPromptLabel.style.display = 'block';
	promptsContainer.appendChild(generalPromptLabel);

	const generalPromptTextarea = document.createElement('textarea');
	generalPromptTextarea.style = `
		width: 100%;
		height: 100px;
		margin-bottom: 12px;
		padding: 8px;
		border-radius: 4px;
		border: 1px solid rgb(192, 192, 192);
	`;
	generalPromptTextarea.value = defaultGeneralPrompt;
	promptsContainer.appendChild(generalPromptTextarea);

	const languageSpecificPromptLabel = document.createElement('label');
	languageSpecificPromptLabel.innerText = 'Prompt per language:';
	languageSpecificPromptLabel.style.display = 'block';
	promptsContainer.appendChild(languageSpecificPromptLabel);

	const languageSpecificPromptTextarea = document.createElement('textarea');
	languageSpecificPromptTextarea.style = `
		width: 100%;
		height: 100px;
		margin-bottom: 12px;
		padding: 8px;
		border-radius: 4px;
		border: 1px solid rgb(192, 192, 192);
	`;
	languageSpecificPromptTextarea.value = defaultLangSpecificPrompt;
	promptsContainer.appendChild(languageSpecificPromptTextarea);

	// Show/hide prompts based on selected service
	serviceDropdown.addEventListener('change', () => {
		promptsContainer.style.display = serviceDropdown.value === 'chatgpt' ? 'block' : 'none';
	});

	/* Translate button */
	let resolve = null;
	let reject = null;
	const selectedLanguages = [];
	const selectedKeys = [];
	let selectedService = '';
	let generalPrompt = '';
	let languageSpecificPrompt = '';

	const translateButton = document.createElement('button');
	translateButton.innerText = 'Translate';
	translateButton.onclick = () => {
		languages.forEach(language => {
			const checkbox = languageGrid.querySelector(`input[name='${language}']`);
			if (checkbox.checked) {
				selectedLanguages.push(checkbox.name.split(' - ')[1]);
			}
		});

		if (keys.length) {
			keys.forEach(key => {
				const checkbox = keySelector.querySelector(`input[name='${key}']`);
				if (checkbox.checked) {
					selectedKeys.push(checkbox.name);
				}
			});
		}

		selectedService = serviceDropdown.value;

		if (selectedService === 'chatgpt') {
			generalPrompt = generalPromptTextarea.value;
			languageSpecificPrompt = languageSpecificPromptTextarea.value;

			if (!generalPrompt || !languageSpecificPrompt) {
				alert('Please provide both general and languageSpecific prompts for ChatGPT translation.');
				return;
			}
		}

		translator.remove();
		pseudo.remove();

		resolve();
	};

	pseudo.onclick = () => {
		translator.remove();
		pseudo.remove();

		reject();
	};

	translateButton.classList.add(...classNames(css.button, {
		[css.primary]: true,
	}).split(' '));
	translateButton.style.display = 'block';
	translateButton.style.margin = '12px auto';

	translator.appendChild(translateButton);

	await new Promise((res, rej) => {
		resolve = res;
		reject = rej;
	});

	return [selectedLanguages, selectedKeys, selectedService, generalPrompt, languageSpecificPrompt];
};
