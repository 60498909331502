import PT from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Fuse from 'fuse.js';

import { callApi } from 'app/actions/api';
import { getLocalisations, createLocalisation } from 'app/epics/localisations';
import { selectAPIStatus } from 'app/selectors/api';
import { selectLocalisations } from 'app/selectors/localisations';
import Button from 'app/components/ui/Button';
import DataTable from 'app/components/ui/DataTable';

import * as types from 'app/types';
import css from 'app/styles/pages/localisations.css';

export class LocalisationsOverview extends React.PureComponent {
	static propTypes = {
		dispatch: PT.func.isRequired,
		localisations: PT.arrayOf(PT.shape({
			id: PT.string,
			title: PT.string,
		})).isRequired,
		getLocalisationsAPIStatus: types.apiStatus.isRequired,
	};

	static columns = [
		{
			selector: row => <span className={css.long}>{`${row.title}`}</span>,
			width: '500px',
			name: 'Content',
			sortable: true,
		},
		{
			selector: row => <Link to={`/localisations/${row.id}/edit`}>{`${row.id}`}</Link>,
			name: 'ID',
			sortable: true,
		},
	];

	componentDidMount() {
		const { dispatch } = this.props;
		dispatch(callApi(getLocalisations.id));
	}

	onAddClick = () => {
		const { dispatch } = this.props;
		let id = prompt('Enter the ID of the new localisation');

		if (id) {
			// regex only a-z, 0-9, and _
			const regex = /^[a-z0-9_]+$/;
			if (id.match(regex) === null) {
				alert('Invalid localisation name: only a-z, 0-9, and _ are allowed');
				return;
			}
			id = id.toLowerCase();
			dispatch(callApi(createLocalisation.id, { id }));
		}
	};

	render() {
		const { getLocalisationsAPIStatus, localisations } = this.props;

		const searchIndex = new Fuse(localisations, {
			caseSensitive: false,
			shouldSort: true,
			threshold: 0.3,
			distance: 100,
			findAllMatches: true,
			useExtendedSearch: true,
			keys: [
				'id',
				'title',
			],
		});

		return (
			<>
				<Button onClick={this.onAddClick}>Add Localisation</Button>
				<DataTable
					columns={LocalisationsOverview.columns}
					data={localisations}
					searchIndex={searchIndex}
					apiStatus={getLocalisationsAPIStatus}
				/>
			</>
		);
	}
}

export default connect(state => ({
	getLocalisationsAPIStatus: selectAPIStatus(state, getLocalisations.id),
	localisations: selectLocalisations(state),
}))(LocalisationsOverview);
