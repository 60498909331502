export function sitesSchema({ sites }) {
	const domains = Object.keys(sites).map(id => ({ id: parseInt(id, 10), domain: sites[id].domain }));

	return {
		// Our sites form
		type: 'object',
		required: [],
		properties: {
			sites: {
				type: 'array',
				title: 'Live on',
				uniqueItems: true,
				items: {
					type: 'number',
					enum: domains.map(d => d.id),
					enumNames: domains.map(d => d.domain),
				},
			},
		},
	};
}

export const uiSitesSchema = {
	sites: {
		'ui:widget': 'checkboxes',
	},
};
