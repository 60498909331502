import React from 'react';

export default class IconCross extends React.PureComponent {
	render() {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
				<path
					d="M26.121 24l7.44-7.44a1.5 1.5 0 1 0-2.121-2.12L24 21.878l-7.44-7.44a1.5 1.5 0 0 0-2.12 2.122L21.878 24l-7.44 7.44a1.5 1.5 0 1 0 2.122 2.12L24 26.122l7.44 7.44a1.5 1.5 0 1 0 2.12-2.121z"
				/>
			</svg>
		);
	}
}
