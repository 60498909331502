import React from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';

import { callApi } from 'app/actions/api';
import { getLocalisation } from 'app/epics/localisations';
import { selectLocalisation } from 'app/selectors/localisations';
import { selectAPIStatus } from 'app/selectors/api';
import Title from 'app/components/ui/Title';
import LocalisationEditor from 'app/components/editors/LocalisationEditor';
import * as types from 'app/types';

class LocalisationsEdit extends React.PureComponent {
	static propTypes = {
		dispatch: PT.func.isRequired,
		localisation: types.localisation,
		match: PT.shape({
			params: PT.shape({
				id: PT.string.isRequired,
			}).isRequired,
		}).isRequired,
		apiStatus: types.apiStatus.isRequired,
	};

	static defaultProps = {
		localisation: undefined,
	};

	componentDidMount() {
		const { dispatch, match } = this.props;

		dispatch(callApi(getLocalisation.id, { id: match.params.id }));
	}

	render() {
		const { apiStatus, localisation, match } = this.props;

		return (
			<>
				<Title>
					{match.params.id}
					{' '}
					– Edit localization
				</Title>
				{apiStatus.pending ? (
					<div>Loading...</div>
				) : apiStatus.error ? (
					<div>Error</div>
				) : !localisation ? (
					<div>Localizations are not found</div>
				) : (
					<LocalisationEditor
						localisation={localisation}
						id={match.params.id}
					/>
				)}
			</>
		);
	}
}

export default connect((state, { match }) => ({
	localisation: selectLocalisation(state, match.params.id),
	apiStatus: selectAPIStatus(state, getLocalisation.id),
}))(LocalisationsEdit);
