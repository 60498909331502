import {
	updateQuery,
} from 'app/actions/search';

export const initialState = {
	query: '',
};

export default function searchReducer(state = initialState, action) {
	if (action.type === updateQuery.type) {
		return {
			...state,
			query: action.query,
		};
	}

	return state;
}
