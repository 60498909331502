import { apiSuccess } from 'app/actions/api';
import { searchRedirects } from 'app/epics/redirects';

export const initialState = {
	data: [],
};

export default function redirectsReducer(state = initialState, action) {
	if (action.type === apiSuccess.type) {
		if (action.id === searchRedirects.id) {
			return {
				...state,
				data: action.response,
			};
		}
	}

	return state;
}
