import PT from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { callApi } from 'app/actions/api';
import { getCategory } from 'app/epics/categories';
import Title from 'app/components/ui/Title';
import { selectCategory } from 'app/selectors/categories';
import * as types from 'app/types';
import MediaEditor from 'app/components/editors/MediaEditor';

export class CategoryMediaEdit extends React.PureComponent {
	static propTypes = {
		dispatch: PT.func.isRequired,
		category: types.category,
		match: PT.shape({
			params: PT.shape({
				id: PT.string.isRequired,
			}).isRequired,
		}).isRequired,
	};

	static defaultProps = {
		category: {},
	};

	componentDidMount() {
		const { dispatch, match } = this.props;
		const id = parseInt(match.params.id, 10);

		dispatch(callApi(getCategory.id, { id }));
	}

	render() {
		const { category } = this.props;

		const englishTitle = category?.titles?.find(t => t.lang === 'en')?.content;
		const image = category?.image;

		if (!category) {
			return <div>Loading</div>;
		}

		return (
			<div>
				<Title image={image}>
					{englishTitle}
					{' '}
					- Media
				</Title>
				<MediaEditor mediaCollectionID={category.media_collection_id} />
			</div>
		);
	}
}

export default connect((state, { match }) => ({
	category: selectCategory(state, match.params.id),
}))(CategoryMediaEdit);
