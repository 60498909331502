import PT from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import css from 'app/styles/pages/adstxt.css';
import Button from 'app/components/ui/Button';
import * as types from 'app/types';
import { callApi } from 'app/actions/api';
import { getAdsTxt, updateAdsTxt } from 'app/epics/adstxt';
import { selectAPIStatus } from 'app/selectors/api';
import { selectAdsTxt } from 'app/selectors/adstxt';
import { getSites } from 'app/epics/sites';
import { selectSites } from 'app/selectors/sites';

const GOOGLE_SHEET_DOC = 'https://docs.google.com/spreadsheets/d/1Uodnzy-gPBMZUPDW1i-vUQMspebDTYlTfZICEziK2gQ/edit#gid=2117976019';

function cleanTxt(txt) {
	// deduplicate lines
	const arr = txt.split('\n');
	if (arr) {
		for (let i = 0; i < arr.length; i++) {
			// trim whitespace
			arr[i] = arr[i].trim();
			const searchFor = arr[i];
			// skip empty lines
			if (searchFor === '') continue;
			for (let j = i + 1; j < arr.length; j++) {
				if (arr[j].trim() === searchFor) {
					arr.splice(j, 1);
					j--;
				}
			}
		}
		txt = arr.join('\n');
	}

	return txt;
}

export class AdsTxtEditor extends React.PureComponent {
	static propTypes = {
		dispatch: PT.func.isRequired,
		adsTxt: PT.string.isRequired,
		updateAdsTxtAPIStatus: types.apiStatus.isRequired,
		sites: PT.objectOf(types.site).isRequired,
	};

	constructor(props) {
		super(props);
		this.textArea = React.createRef();
		this.state = {
			adsTxt: props.adsTxt,
			edited: false,
			userUnderstands: false,
			siteID: -1,
		};

		this.handleUserUnderstandsChange = this.handleUserUnderstandsChange.bind(this);
	}

	componentDidMount() {
		const { dispatch } = this.props;
		dispatch(callApi(getSites.id));
		dispatch(callApi(getAdsTxt.id, -1));
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		const { adsTxt } = this.props;
		if (nextProps.adsTxt !== adsTxt) {
			this.setState({ adsTxt: nextProps.adsTxt });
		}
	}

	handleSaveAdsTxt = () => {
		const { dispatch } = this.props;
		const { siteID } = this.state;

		const adsTxt = cleanTxt(this.textArea.current.value);
		if (siteID === -1 && adsTxt === '') {
			// eslint-disable-next-line no-alert
			alert('All ads.txt should not be empty');
			return;
		}
		this.setState({ edited: false });
		dispatch(callApi(updateAdsTxt.id, { siteID, data: adsTxt }));
	};

	textChanged = () => {
		this.setState({ adsTxt: this.textArea.current.value, edited: true });
	};

	siteChanged = event => {
		const siteID = event.target.value;

		this.setState({
			siteID,
			userUnderstands: false,
			edited: false,
		}, () => {
			const { dispatch } = this.props;

			dispatch(callApi(getAdsTxt.id, siteID));
		});
	};

	handleUserUnderstandsChange = () => {
		const { userUnderstands } = this.state;
		this.setState({
			userUnderstands: !userUnderstands,
		});
	};

	render() {
		const { updateAdsTxtAPIStatus, sites } = this.props;
		const { edited, adsTxt, site, userUnderstands } = this.state;

		const { success, pending } = updateAdsTxtAPIStatus;
		const btnDisabled = !edited || pending || !userUnderstands;

		let buttonText = 'Save';
		if (pending) buttonText = 'Saving..';
		if (success && !edited) buttonText = 'Saved';

		return (
			<>
				<div className={css.disclaimer}>
					<p>
						Please use the
						<a href={GOOGLE_SHEET_DOC} target="_blank" rel="noreferrer">Google sheets ads.txt file</a>
						{' '}
						to make any changes to the Poki Ads.txt.
					</p>
					<p>All ads.txt lines that are to be used globally should be copy-pasted from column A. Follow the instructions in the sheet for additional information and contact the Rev. Ops channel for any extra details.</p>
				</div>
				<label className={css.siteselectLabel}>
					<span>Site:</span>
					<select className={css.siteselect} value={site} onChange={this.siteChanged}>
						<option key="-1" value="-1">All</option>
						{Object.keys(sites).map(id => (
							<option key={id} value={id}>{sites[id].domain}</option>
						))}
					</select>
				</label>
				<textarea className={css.textarea} value={adsTxt} onChange={this.textChanged} ref={this.textArea} readOnly={pending} />
				<hr />
				<label className={css.userUnderstandsLabel}>
					<input type="checkbox" className={css.userUnderstandsInput} onChange={this.handleUserUnderstandsChange} defaultChecked={userUnderstands} />
					I have made my changes in the Google Sheets ads.txt file and copy pasted the relevant column to above
				</label>
				<Button primary submit disabled={btnDisabled} onClick={this.handleSaveAdsTxt}>{buttonText}</Button>
			</>
		);
	}
}
export default connect(state => ({
	adsTxt: selectAdsTxt(state),
	updateAdsTxtAPIStatus: selectAPIStatus(state, updateAdsTxt.id),
	sites: selectSites(state),
}))(AdsTxtEditor);
