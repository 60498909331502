import { apiEpic } from 'app/utils/api';

export const getGame = apiEpic(
	'games/get',
	(api, { id }) => api(`/secure/game/${id}`),
);

export const getAllGames = apiEpic(
	'games/all',
	api => api('/secure/all/games'),
);

export const createGame = apiEpic(
	'games/create',
	(api, data) => api('/secure/game', {
		body: data,
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
	}),
);

export const updateGame = apiEpic(
	'games/update',
	(api, data) => api(`/secure/game/${data.id}`, {
		body: data,
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
		},
	}),
);

export const updateGameCategories = apiEpic(
	'games/update-categories',
	(api, { id, data }) => api(`/secure/game-category/${id}`, {
		body: data,
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
		},
	}),
);
