import React from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';

import CategoryOverview from 'app/components/pages/CategoryOverview';
import CategoryEdit from 'app/components/pages/CategoryEdit';
import CategoryGamesEdit from 'app/components/pages/CategoryGamesEdit';
import CategoryMediaEdit from 'app/components/pages/CategoryMediaEdit';
import Dashboard from 'app/components/pages/Dashboard';
import CategoryAdd from 'app/components/pages/CategoryAdd';
import GameEdit from 'app/components/pages/GameEdit';
import GameOverview from 'app/components/pages/GameOverview';
import AdsTxtEditor from 'app/components/pages/AdsTxtEditor';
import AdSettingsEditor from 'app/components/pages/AdSettingsEditor';
import GameMediaEdit from 'app/components/pages/GameMediaEdit';
import GameCategoriesEdit from 'app/components/pages/GameCategoriesEdit';
import LocalisationsOverview from 'app/components/pages/LocalisationsOverview';
import LocalisationsEdit from 'app/components/pages/LocalisationsEdit';
import RedirectsList from 'app/components/pages/RedirectsList';
import RedirectsAdd from 'app/components/pages/RedirectsAdd';
import AdsOverview from 'app/components/pages/AdsOverview';
import CreativesAdd from 'app/components/pages/CreativesAdd';
import BulkHouseAdsAdd from 'app/components/pages/BulkHouseAdsAdd';
import CreativesOverview from 'app/components/pages/CreativesOverview';
import CreativesEdit from 'app/components/pages/CreativesEdit';
import SeriesOverview from 'app/components/pages/SeriesOverview';
import SeriesDelete from 'app/components/pages/SeriesDelete';

import SideBar from 'app/components/SideBar';
import BlockingInit from 'app/components/BlockingInit';

import css from 'app/styles/pages/app.css';
import SeriesView from 'app/components/pages/SeriesView';

export class App extends React.PureComponent {
	render() {
		if (!window.localStorage.getItem('refresh_token')) {
			return <Redirect to="/login" />;
		}

		return (
			<>
				<BlockingInit />
				<div className={css.app}>
					<div className={css.row}>
						<SideBar />

						<div className={css.content}>
							<Switch>
								{/* Root */}
								<Route exact path="/" component={Dashboard} />

								{/* Categories */}
								<Route exact path="/categories" component={CategoryOverview} />
								<Route exact path="/categories/add" component={CategoryAdd} />
								<Route exact path="/categories/:id/edit" component={CategoryEdit} />
								<Route exact path="/categories/:id/media" component={CategoryMediaEdit} />
								<Route exact path="/category-game/:id/edit" component={CategoryGamesEdit} />

								{/* Series */}
								<Route exact path="/series" component={SeriesOverview} />
								<Route exact path="/series/:id/view" component={SeriesView} />
								<Route exact path="/series/delete/:id/:title" component={SeriesDelete} />

								{/* Games */}
								<Route exact path="/games" component={GameOverview} />
								<Route exact path="/games/:id/edit" component={GameEdit} />
								<Route exact path="/games/:id/media" component={GameMediaEdit} />
								<Route exact path="/games/:id/categories" component={GameCategoriesEdit} />

								{/* Localisations */}
								<Route exact path="/localisations" component={LocalisationsOverview} />
								<Route exact path="/localisations/:id/edit" component={LocalisationsEdit} />

								{/* ads */}
								<Route exact path="/ads" component={AdsOverview} />

								{/* Ads.txt */}
								<Route exact path="/ads/adstxt" component={AdsTxtEditor} />

								{/* Ad Settings */}
								<Route exact path="/ads/adsettings" component={AdSettingsEditor} />

								{/* Creatives Config */}
								<Route exact path="/ads/creatives" component={CreativesOverview} />
								<Route exact path="/ads/creatives/:id/edit" component={CreativesEdit} />
								<Route exact path="/ads/creatives/add" component={CreativesAdd} />
								<Route exact path="/ads/creatives/bulkhouseads" component={BulkHouseAdsAdd} />

								{/* Redirects */}
								<Route exact path="/redirects" component={RedirectsList} />
								<Route exact path="/redirects/add" component={RedirectsAdd} />
							</Switch>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withRouter(App);
