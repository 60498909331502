import React from 'react';
import PT from 'prop-types';

import css from 'app/styles/ui/game-info.css';
import Picture from 'app/components/ui/Picture';
import CategoryList from 'app/components/ui/CategoryList';
import * as types from 'app/types';
import { copyToClipboard } from 'app/utils/copyText';

class GameInfo extends React.PureComponent {
	static propTypes = {
		image: types.image.isRequired,
		id: PT.string.isRequired,
		title: PT.string.isRequired,
		categories: PT.arrayOf(types.categorySummary).isRequired,
	};

	constructor(props) {
		super(props);
		this.state = {
			copied: false,
		};
	}

	copyID = () => {
		const { id } = this.props;
		copyToClipboard(id);
		this.setState({ copied: true });
	};

	render() {
		const { image, title, id, categories } = this.props;
		const { copied } = this.state;

		return (
			<div className={css.gameInfo}>
				{typeof image !== 'undefined' && (
					<Picture
						className={css.image}
						image={image}
						size={[50, 50]}
					/>
				)}
				<div className={css.info}>
					<h1>{title}</h1>
					<button type="button" onClick={this.copyID}>{`${id} ${copied ? '(copied)' : ''}`}</button>
				</div>
				{categories && (
					<CategoryList categories={categories} className={css.categories} />
				)}
			</div>
		);
	}
}

export default GameInfo;
